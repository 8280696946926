import React from 'react';
import { keyframes } from '@emotion/react';

import Box from '@mui/material/Box';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export default function Loader() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        minHeight: '200px',
      }}
    >
      <Box
        sx={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          border: '5px solid #a5a5a5',
          position: 'relative',
          animation: `${spin} 800ms linear infinite`,
          '&::after': {
            content: '""',
            display: 'flex',
            width: '40px',
            height: '40px',
            position: 'absolute',
            left: '-20px',
            top: '-20px',
            background: '#F5F3F1',
          }
        }}
      />
    </Box>
  )
}
