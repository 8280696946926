import React, { useContext } from 'react';
import ReactPDF, {
  Document, Image, Page, Path, StyleSheet, Svg, Text, View,
} from '@react-pdf/renderer';
import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg';
import { Chart as ChartJS } from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { gridExpandedSortedRowIdsSelector } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internal/models';

import { shortenLink } from '../apis/shortLinks';
import { SessionContext } from '../auth';
import { DashboardType, IAggregatedStats, ICommonCampaignRow } from '../types/global';
import { IProfile } from '../types/users';
import { makeFormatCurrency, makeFormatPercent } from '../utils/format';
import { useQuery } from '../utils/QueryParamsContext';

import { formatValue } from './DateRangePicker';
import { Footer, Header, HeaderProps, makeChartImage, useDownloadPdf, useHeaderProps } from './pdfCommon';

interface PDFRow {
  id: string;
  campaign: string;
  products: string[];
  starts: string;
  ends: string;
  incrementalRevenue: string;
  conversionChange: string;
  cartCreationChange: string;
  grossRevenue: string;
  revenuePerVisit: string;
  revenuePerShopper: string;
}

interface PDFDownloadProps {
  dateRange: NonEmptyDateRange<Dayjs> | null;
  aggregatedStats: IAggregatedStats | null;
  chartRef: React.RefObject<ChartJS>;
  dashboardType: DashboardType;
  apiRef: React.MutableRefObject<GridApiPro>;
  selectedOffers: ICommonCampaignRow[] | null;
}

interface ReportProps {
  headerProps: HeaderProps;
  aggregatedStats: IAggregatedStats;
  chartRef: React.RefObject<ChartJS>;
  rows: PDFRow[];
  profile: IProfile;
  chartImage: string;
  dashboardType: DashboardType;
}

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Soleil',
    padding: '21pt 30pt 6pt 30pt',
  },
  aggregatedStats: {
    marginTop: '18pt',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  statValue: {
    fontSize: '33pt',
    fontWeight: 800,
    color: '#8E8E8E',
    alignSelf: 'flex-end',
  },
  statLabel: {
    backgroundColor: '#6A7AB8',
    fontSize: '10pt',
    fontWeight: 800,
    color: 'white',
    textTransform: 'uppercase',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '0 4pt',
  },
  chartImage: {
    margin: '5px 0',
    width: '100%',
  },
  bar: {
    height: '14pt',
    backgroundColor: '#6A7AB8',
    marginBottom: '5pt',
    color: 'white',
    fontSize: '10pt',
    fontWeight: 800,
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'center',
  },
  barContent: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  barWarningIcon: {
    transform: 'scale(0.75)',
    marginRight: '3pt',
  },
  tableRow: {
    borderBottom: '0.5pt solid #E6E8F0',
    height: '27pt',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRow: {
    height: '50pt',
    paddingBottom: '10pt',
  },
  tableCell: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '76pt',
    fontSize: '9pt',
    color: '#616161',
    paddingLeft: '12pt',
  },
  lastCell: {
    paddingRight: '12pt',
    width: '90pt',
  },
  headerCell: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    fontSize: '8pt',
    color: '#4559A7',
    textTransform: 'uppercase',
    height: '100%',
  },
  headerCellContent: {
    backgroundColor: 'red',
    marginTop: 'auto',
    height: '8pt',
  },
  headerCellRight: {
    alignItems: 'flex-end',
    textAlign: 'right',
  },
  alignRight: {
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  product: {
    padding: '0 5pt',
    backgroundColor: '#8E8E8E',
    color: 'white',
    borderRadius: '10pt',
    marginRight: '3pt',
    textTransform: 'uppercase',
    fontSize: '7pt',
    fontWeight: 800,
  },
  dateWarning: {
    transform: 'scale(0.75)',
    marginLeft: '2pt',
  },
  column1: {
    width: '70pt',
  },
  column2: {
    width: '90pt',
  },
  column3: {
    width: '80pt',
  },
  column4: {
    width: '66pt',
  },
  table: {
    marginBottom: '8pt',
  },
});

function WarningIcon(props: ReactPDF.SVGProps) {
  return (
    <Svg width="17" height="16" viewBox="0 0 17 16" {...props}>
      <Path
        fill="white"
        d="M8.98151 1.56642C8.53763 0.811193 7.46546 0.811193 7.02158 1.56642L0.164626 13.2331C-0.292407 14.0107 0.256279 15 1.14459 15H14.8585C15.7468 15 16.2955 14.0107 15.8385 13.2331L8.98151 1.56642Z"
      />
      <Path
        fill="#FB8C00"
        d="M8.98151 1.56642C8.53763 0.811193 7.46546 0.811193 7.02158 1.56642L0.164626 13.2331C-0.292407 14.0107 0.256279 15 1.14459 15H14.8585C15.7468 15 16.2955 14.0107 15.8385 13.2331L8.98151 1.56642ZM8 5C8.53541 5 8.95377 5.46228 8.9005 5.99504L8.54975 9.50248C8.52151 9.78492 8.28384 10 8 10C7.71616 10 7.47849 9.78492 7.45025 9.50248L7.0995 5.99504C7.04623 5.46229 7.46459 5 8 5ZM8.00154 11C8.55383 11 9.00154 11.4477 9.00154 12C9.00154 12.5523 8.55383 13 8.00154 13C7.44926 13 7.00154 12.5523 7.00154 12C7.00154 11.4477 7.44926 11 8.00154 11Z"
      />
    </Svg>
  );
}

function Report(
  {
    aggregatedStats, profile, chartImage, rows, dashboardType, headerProps,
  }: ReportProps,
) {
  const formatCurrency = makeFormatCurrency(profile);
  const formatCurrency2 = makeFormatCurrency(profile, 2);
  const formatPercent = makeFormatPercent(profile);
  const filteredRows = rows.filter((x) => !('offer' in x) && !('device' in x));
  const { dateRange, fullYearFormat, noYearFormat } = headerProps;
  const isPartialData = filteredRows.some((row) => (
    dateRange && (dayjs(row.starts).isBefore(dateRange[0]) || dayjs(row.ends).isAfter(dateRange[1]))
  ));
  const formattedDateRange = dateRange && formatValue(dateRange, fullYearFormat, noYearFormat);
  return (
    <Document>
      <Page size="LETTER" style={styles.page} wrap>
        <Header {...headerProps} />
        <View style={styles.aggregatedStats}>
          {'incrementalRevenue' in aggregatedStats ? (
            <>
              <View>
                <View style={styles.statValue}>
                  <Text>
                    {formatCurrency({ value: aggregatedStats.incrementalRevenue })}
                  </Text>
                </View>
                <View style={styles.statLabel}>
                  <Text>
                    Net new revenue
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.statValue}>
                  {formatPercent({ value: aggregatedStats.cartCreationChange }, { sign: true })}
                </Text>
                <View style={styles.statLabel}>
                  <Text>
                    Carts created
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.statValue}>
                  {formatPercent({ value: aggregatedStats.conversionChange }, { sign: true })}
                </Text>
                <View style={styles.statLabel}>
                  <Text>
                    Carts saved
                  </Text>
                </View>
              </View>
            </>
          ) : (
            <>
              <View>
                <Text style={styles.statValue}>
                  {formatCurrency({ value: aggregatedStats.grossRevenue })}
                </Text>
                <View style={styles.statLabel}>
                  <Text>
                    Gross revenue
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.statValue}>
                  {formatCurrency2({ value: aggregatedStats.revenuePerVisit })}
                </Text>
                <View style={styles.statLabel}>
                  <Text>
                    Revenue per visit
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.statValue}>
                  {formatCurrency2({ value: aggregatedStats.revenuePerShopper })}
                </Text>
                <View style={styles.statLabel}>
                  <Text>
                    Revenue per shopper
                  </Text>
                </View>
              </View>
            </>
          )}
        </View>
        <Image
          src={chartImage}
          style={styles.chartImage}
        />
        <View style={styles.bar} />
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.headerRow]}>
            <View style={[styles.tableCell, styles.headerCell, styles.column2]}>
              <Text>
                Campaign
              </Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell, styles.column4]}>
              <Text>
                Product
              </Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell, styles.column3]}>
              <Text>
                Starts
              </Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell, styles.column3]}>
              <Text>
                Ends
              </Text>
            </View>
            {dashboardType === DashboardType.Incremental ? (
              <>
                <View style={[styles.tableCell, styles.headerCell, styles.column1, styles.headerCellRight]}>
                  <Text>
                    Net new
                  </Text>
                  <Text>
                    revenue
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.headerCell, styles.column1, styles.headerCellRight]}>
                  <Text>
                    Conversion rate
                  </Text>
                  <Text>
                    % change
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.headerCell, styles.headerCellRight, styles.lastCell]}>
                  <Text>
                    Cart creation
                  </Text>
                  <Text>
                    % change
                  </Text>
                </View>
              </>
            ) : (
              <>
                <View style={[styles.tableCell, styles.headerCell, styles.column1, styles.headerCellRight]}>
                  <Text>
                    Gross
                  </Text>
                  <Text>
                    revenue
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.headerCell, styles.column1, styles.headerCellRight]}>
                  <Text>
                    Revenue
                  </Text>
                  <Text>
                    per visit
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.headerCell, styles.headerCellRight, styles.lastCell]}>
                  <Text>
                    Revenue
                  </Text>
                  <Text>
                    per shopper
                  </Text>
                </View>
              </>
            )}
          </View>
          {filteredRows.map((row) => (
            <View key={row.id} style={styles.tableRow} wrap={false}>
              <Text style={[styles.tableCell, styles.column2]}>
                {row.campaign}
              </Text>
              <View style={[styles.tableCell, styles.column4]}>
                {row.products.sort().map((product) => (
                  <View key={product} style={styles.product}>
                    <Text>
                      {product}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={[styles.tableCell, styles.column3]}>
                <Text>
                  {row.starts}
                </Text>
                {dateRange && dayjs(row.starts).isBefore(dateRange[0]) && (
                  <WarningIcon style={styles.dateWarning} />
                )}
              </View>
              <View style={[styles.tableCell, styles.column3]}>
                <Text>
                  {row.ends}
                </Text>
                {dateRange && dayjs(row.ends).isAfter(dateRange[1]) && (
                  <WarningIcon style={styles.dateWarning} />
                )}
              </View>
              {dashboardType === DashboardType.Incremental ? (
                <>
                  <Text style={[styles.tableCell, styles.column1, styles.alignRight]}>
                    <Text>
                      {row.incrementalRevenue}
                    </Text>
                  </Text>
                  <Text style={[styles.tableCell, styles.column1, styles.alignRight]}>
                    <Text>
                      {row.conversionChange}
                    </Text>
                  </Text>
                  <Text style={[styles.tableCell, styles.alignRight, styles.lastCell]}>
                    <Text>
                      {row.cartCreationChange}
                    </Text>
                  </Text>
                </>
              ) : (
                <>
                  <Text style={[styles.tableCell, styles.column1, styles.alignRight]}>
                    <Text>
                      {row.grossRevenue}
                    </Text>
                  </Text>
                  <Text style={[styles.tableCell, styles.column1, styles.alignRight]}>
                    <Text>
                      {row.revenuePerVisit}
                    </Text>
                  </Text>
                  <Text style={[styles.tableCell, styles.alignRight, styles.lastCell]}>
                    <Text>
                      {row.revenuePerShopper}
                    </Text>
                  </Text>
                </>
              )}
            </View>
          ))}
        </View>
        <View style={styles.bar} wrap={false}>
          {isPartialData && (
            <View style={styles.barContent}>
              <WarningIcon style={styles.barWarningIcon} />
              <Text>
                Table shows data for the period {formattedDateRange}.
              </Text>
            </View>
          )}
        </View>
        <Footer />
      </Page>
    </Document>
  );
}

export default function PDFDownload(props: PDFDownloadProps) {
  const {
    aggregatedStats,
    dateRange,
    apiRef,
    selectedOffers,
    ...rest
  } = props;
  const { profile } = useContext(SessionContext) as { profile: IProfile };
  const { chartRef } = props;
  const headerProps = useHeaderProps();
  const disabled = !aggregatedStats || !headerProps;
  const query = useQuery();
  const downloadPdf = useDownloadPdf();
  const button = (
    <IconButton
      sx={{
        margin: '-5px -9px -5px 4px',
        display: { xs: 'none', lg: 'inline-flex' },
      }}
      onClick={async () => {
        if (!aggregatedStats || !headerProps || !dateRange) {
          return;
        }
        const short = await shortenLink(`dashboard?${query}`);
        const image = await makeChartImage(chartRef.current as ChartJS, { width: 1000, height: 350 });
        const filteredSortedRowIds = gridExpandedSortedRowIdsSelector(apiRef);
        const rows = filteredSortedRowIds.map((id): PDFRow => {
          const columns = [
            'campaign', 'starts', 'ends', 'incrementalRevenue', 'conversionChange',
            'cartCreationChange', 'grossRevenue', 'revenuePerVisit', 'revenuePerShopper',
          ];
          return {
            id: `${id}`,
            products: apiRef.current.getCellParams(id, 'products').value,
            ...Object.fromEntries(columns.map((column) => (
              [column, apiRef.current.getCellParams(id, column).formattedValue]
            ))),
          } as PDFRow;
        }).filter((row) => (
          !selectedOffers || selectedOffers.find((x) => x.campaign === row.campaign)
        ));
        await downloadPdf(
          <Report
            profile={profile}
            chartImage={image}
            rows={rows}
            aggregatedStats={aggregatedStats}
            headerProps={{
              ...headerProps,
              dateRange,
              shortLink: short,
            }}
            {...rest}
          />,
          dateRange,
        );
      }}
      disabled={disabled}
    >
      <PDFIcon />
    </IconButton>
  );
  return disabled ? button : (
    <Tooltip title="Download PDF report">
      {button}
    </Tooltip>
  );
}
