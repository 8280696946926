export enum ChartType {
  'Bar',
  Donut,
}

export enum DataLabelType {
  Number,
  Currency,
  Currency2,
}
