import React, { useContext, useMemo, useRef } from 'react';
import { Chart as ChartJS } from 'chart.js';
import dayjs from 'dayjs';
import _ from 'lodash';

import Box from '@mui/material/Box';

import { SessionContext } from '../../auth';
import ChartContainer from '../../components/DataGrid/ChartContainer';
import DateRangePicker from '../../components/DateRangePicker';
import Loader from '../../components/Loader';
import Share from '../../components/Share';
import { DashboardType } from '../../types/global';
import { IProfile } from '../../types/users';
import { makeFormatCurrency, makeFormatNumber } from '../../utils/format';
import { useQueryStateDateRange } from '../../utils/QueryParamsContext';
import DashboardContext, { DashboardContextValue } from '../DashboardContext';
import { useTrendColors } from '../dashboardHooks';

import Chart from './Chart';
import PDFDownload from './PDFDownload';
import StatElement from './StatElement';
import { ChartType, DataLabelType } from './types';
import useData from './useData';

const getCampaignTypeLabel = (type: string) => {
  switch (type) {
    case 'cc':
      return 'Cart Creator';
    case 'social':
      return 'Social Proof';
    case 'rules':
      return 'Rules';
    case 'cs':
      return 'Cart Saver';
    default:
      return '';
  }
};

const getCampaignTypeColor = (type: string) => {
  switch (type) {
    case 'cc':
      return '#E06307';
    case 'social':
      return '#EBB114';
    case 'rules':
      return '#1FA219';
    case 'cs':
      return '#99319D';
    default:
      return '#000000';
  }
};

const noop = () => {};
const emptyArray: any[] = [];

export default function Exec() {
  const { profile } = useContext(SessionContext) as { profile: IProfile };
  const formatNumber = makeFormatNumber(profile);
  const formatCurrency = makeFormatCurrency(profile);
  const formatCurrency2 = makeFormatCurrency(profile, 2);

  const today = dayjs().startOf('day');
  const [dateRange, setDateRange] = useQueryStateDateRange('date-range', [
    dayjs(today).subtract(30, 'days'),
    today,
  ]);

  const {
    isLoading, stats, campaignTypes, campaignChartData, chartRows, rows, totalCampaigns,
  } = useData(dateRange);
  const [trendColors, setTrendColors] = useTrendColors(rows);
  const contextValue = useMemo((): DashboardContextValue => ({
    dateRange,
    hoveredTrend: null,
    setHoveredTrend: noop,
    trendColors,
    setTrendColors,
    apiRef: { current: null as any },
  }), [dateRange, trendColors]);
  
  const getChartData = (field: string) => {
    const nonEmpty = Object.entries(campaignTypes)
      .filter(([, values]) => values[field] !== 0);
    return _.sortBy(
      nonEmpty.map(([type, values]) => ({
        label: getCampaignTypeLabel(type),
        value: values[field],
        color: getCampaignTypeColor(type),
      })),
      (x) => -x.value,
    );
  };
  
  const countChartRef = useRef<ChartJS>(null);
  const revenueChartRef = useRef<ChartJS>(null);
  const rpvChartRef = useRef<ChartJS>(null);
  const bottomChartRef = useRef<ChartJS>(null);

  const renderContent = () => {
    if (isLoading) {
      return (
        <Loader />
      );
    }
    if (!campaignChartData || !chartRows) {
      return null;
    }
    return (
      <DashboardContext.Provider value={contextValue}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '16px',
              flexShrink: 0,
            }}
          >
            <StatElement label="Metrical engaged" value={`${stats.engagedPercent}%`} />
            <StatElement label="Visitors engaged by Metrical" value={formatNumber({ value: stats.visitorsEngaged })} />
            <StatElement label="Net new revenue" value={formatCurrency({ value: stats.netNewRevenue })} />
            <StatElement label="RPV" value={formatCurrency2({ value: stats.rpv })} />
            <StatElement label="Conversion rate % change" value={`${stats.conversionChange}%`} />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'stretch',
              margin: '0 -8px',
            }}
          >
            <Chart
              id="count"
              title="Campaigns run"
              data={getChartData('count')}
              dataLabelType={DataLabelType.Number}
              chartTypes={[ChartType.Bar, ChartType.Donut]}
              ref={countChartRef}
            />
            <Chart
              id="revenue"
              title="Net new revenue by campaign types"
              data={getChartData('netNewRevenue')}
              dataLabelType={DataLabelType.Currency}
              chartTypes={[ChartType.Bar]}
              ref={revenueChartRef}
            />
            <Chart
              id="rpv"
              title="RPV"
              data={getChartData('rpv')}
              dataLabelType={DataLabelType.Currency2}
              chartTypes={[ChartType.Bar]}
              ref={rpvChartRef}
            />
          </Box>
        </Box>
        <ChartContainer
          chartData={emptyArray}
          campaignChartData={campaignChartData}
          selectedOffers={null}
          minDate={dateRange[0].format('YYYY-MM-DD')}
          maxDate={dateRange[1].format('YYYY-MM-DD')}
          filteredOffers={emptyArray}
          setAggregatedStats={noop}
          dashboardType={DashboardType.Incremental}
          chartRef={bottomChartRef}
          isExec
          gridRows={chartRows}
        />
      </DashboardContext.Provider>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          margin: '16px 0',
        }}
      >
        <DateRangePicker
          value={dateRange}
          onChange={setDateRange}
          TextFieldProps={{
            sx: { marginLeft: 1.75, width: 244 },
            'aria-label': 'Select date range',
          }}
        />
        <PDFDownload
          dateRange={dateRange}
          stats={stats}
          countChartRef={countChartRef}
          revenueChartRef={revenueChartRef}
          rpvChartRef={rpvChartRef}
          bottomChartRef={bottomChartRef}
          totalCampaigns={totalCampaigns}
        />
        <Share isExec />
      </Box>
      {renderContent()}
    </Box>
  );
}
