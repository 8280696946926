import { useEffect } from 'react';
import color from 'color';

import { IGrossRow, IIncrementalRow } from '../types/global';
import { useQueryParams, useQueryState } from '../utils/QueryParamsContext';

import { SummaryTrendColor, TrendColor } from './DashboardContext';

const COLORS = ['#C41515', '#E06307', '#EBB114', '#1FA219', '#0F8044', '#199CE5', '#4051B6', '#050074', '#050074', '#EB6DD3'];

export const useTrendColors = (rows: (IIncrementalRow | IGrossRow)[] | null) => {
  const queryState = useQueryState<TrendColor[]>('trend-colors', []);
  const [, setTrendColors] = queryState;
  const { 'trend-colors': paramsTrendColors } = useQueryParams();
  useEffect(() => {
    if (rows && !(paramsTrendColors?.length > 0)) {
      const campaignColors = rows.filter((x) => !('offer' in x)).map((x, i) => ({
        campaign: x.campaign,
        color: COLORS[i % COLORS.length],
      }));
      const offerColors = rows.filter((x) => 'offer' in x && !('device' in x)).map((x, i) => {
        const campaignColor = campaignColors.find((y) => y.campaign === x.campaign);
        const offerIndex = rows.filter((y) => (
          y.campaign === x.campaign && 'offer' in y && !('device' in y)
        )).findIndex((y) => y.offer === x.offer);
        const darkenRatios = [0.2, 0.4, 0.6, 0.8, 1, 1.2];
        const lightenRatios = [0.5, 1, 1.5, 2, 2.5, 3];
        const offerColor = (() => {
          if (!campaignColor) {
            return COLORS[i % COLORS.length];
          }
          const colorObject = color(campaignColor.color);
          const ratios = colorObject.luminosity() > 0.1 ? darkenRatios : lightenRatios;
          return colorObject.lighten(ratios[offerIndex % ratios.length]).hex();
        })();
        return {
          campaign: x.campaign,
          offer: x.offer,
          color: offerColor,
        };
      });
      const summaryColor: SummaryTrendColor = { isSummary: true, color: '#EB6DD3' };
      setTrendColors([...campaignColors, ...offerColors, summaryColor]);
    }
  }, [rows, paramsTrendColors]);
  return queryState;
};
