import React, { useContext } from 'react';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import dayjs, { Dayjs } from 'dayjs';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import DashboardContext from '../../pages/DashboardContext';
import { useFullYearFormat } from '../../utils/format';

export default function StartsCell({ value, formattedValue }: GridRenderCellParams<any, Dayjs>) {
  const { dateRange } = useContext(DashboardContext);
  const dateFormat = useFullYearFormat();
  const isIncomplete = value && dateRange[0] && dayjs(value).isBefore(dateRange[0]);
  return (
    <>
      {formattedValue}
      {isIncomplete && (
        <Tooltip
          title={
            `Table shows data since ${dayjs(dateRange[0]).format(dateFormat)}.
            For complete campaign data, change the selected period.`
          }
        >
          <Box component={WarningIcon} sx={{ marginLeft: 'auto', flexShrink: 0 }} />
        </Tooltip>
      )}
    </>
  );
}
