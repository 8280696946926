import { useContext, useMemo, useRef, useState } from 'react';
import { CanceledError } from 'axios';
import { Dayjs } from 'dayjs';
import _ from 'lodash';

import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internal/models';

import { getKPI } from '../../apis/dashboard';
import { SessionContext } from '../../auth';
import { CampaignField, campaignFields } from '../../components/DataGrid/Chart';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import { HeaderContext } from '../../layouts/main/header/context';
import { IIncrementalRow } from '../../types/global';

export default function useData(dateRange: NonEmptyDateRange<Dayjs>) {
  const { profile } = useContext(SessionContext);
  const { domainId } = useContext(HeaderContext);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState<IIncrementalRow[] | null>(null);
  const getKPIAbortController = useRef<AbortController | null>(null);
  useAsyncEffect(async () => {
    const type = 'incremental';
    if (getKPIAbortController.current) {
      getKPIAbortController.current.abort();
    }
    getKPIAbortController.current = new AbortController();
    setIsLoading(true);
    setRows(null);
    try {
      const startDate = dateRange[0].format('YYYY-MM-DD');
      const endDate = dateRange[1].format('YYYY-MM-DD');
      const [
        { data },
      ] = await Promise.all([
        getKPI({
          domain: domainId,
          startDate,
          endDate,
          type,
        }, profile!.organizations, getKPIAbortController.current.signal),
      ]);
      setRows(data as any);
      setIsLoading(false);
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
        throw e;
      }
    }
  }, [dateRange, domainId, profile!.organizations]);
  const stats: Record<string, number> = useMemo(() => {
    const campaignRows = rows?.filter((x) => !x.offer) ?? [];
    const totalOffers = _.sumBy(campaignRows, (x) => (x.offers[1] || 0));
    const targetedVisitsSum = _.sumBy(campaignRows, (x) => x.targetedVisits[1] ?? 0);
    const netNewRevenue = _.sumBy(campaignRows, (x) => x.incrementalRevenue);
    const totalTreatmentVisits = _.sumBy(campaignRows, (x) => (x.visits[1] || 0));
    const rpv = totalOffers ? _.sumBy(campaignRows, (x) => (
      (x.revenuePerVisit[1] || 0) * (x.visits[1] || 0)
    ) / totalTreatmentVisits) : 0
    const controlPurchases = _.sumBy(campaignRows, (x) => (x.purchases[0] || 0));
    const controlOffers = _.sumBy(campaignRows, (x) => (x.offers[0] || 0));
    const treatmentPurchases = _.sumBy(campaignRows, (x) => (x.purchases[1] || 0));
    const treatmentOffers = _.sumBy(campaignRows, (x) => (x.offers[1] || 0));
    const controlPurchaseRate = controlPurchases / controlOffers;
    const treatmentPurchaseRate = treatmentPurchases / treatmentOffers;
    const purchaseRateChange = (treatmentPurchaseRate / controlPurchaseRate - 1) * 100;
    return {
      engagedPercent: Number(targetedVisitsSum.toFixed(2)),
      visitorsEngaged: totalOffers,
      netNewRevenue,
      rpv,
      conversionChange: Number(purchaseRateChange.toFixed(2)),
    };
  }, [rows]);
  const campaignTypes: Record<string, Record<string, number>> = useMemo(() => {
    const campaignRows = rows?.filter((x) => !x.offer) ?? [];
    const [social, nonSocial] = _.partition(campaignRows, (x) => x.keywords.includes('social'));
    const cc = nonSocial.filter((x) => x.products.includes('cc'));
    const cs = nonSocial.filter((x) => x.products.includes('cs'));
    const rules = nonSocial.filter((x) => x.products.includes('rule'));
    const typeRowsList: [string, IIncrementalRow[]][] = [
      ['social', social],
      ['cc', cc],
      ['cs', cs],
      ['rules', rules],
    ];
    return Object.fromEntries(typeRowsList.map(([key, typeRows]) => {
      const totalVisits = _.sumBy(typeRows, (x) => (x.visits[1] || 0));
      return [
        key,
        {
          count: typeRows.length,
          netNewRevenue: _.sumBy(typeRows, (x) => x.incrementalRevenue || 0),
          rpv: totalVisits ? _.sumBy(typeRows, (x) => (
            (x.revenuePerVisit[1] || 0) * (x.visits[1] || 0)
          ) / totalVisits) : 0,
        },
      ];
    }));
  }, [rows]);
  const campaignChartData = useMemo(() => {
    if (rows) {
      const campaignRows = rows.filter((x) => !x.offer);
      const mapField = (field: CampaignField): keyof IIncrementalRow => {
        switch (field) {
          case 'newNetRevenue':
            return 'incrementalRevenue';
          case 'purchaseRateChange':
            return 'conversionChange';
          default:
            return field;
        }
      };
      return campaignFields.map((field) => ({
        field,
        data: campaignRows.map((row) => {
          const value = row[mapField(field)] as any;
          return ({
            x: row.campaign,
            y: Array.isArray(value) ? value[1] : value,
          });
        }).filter(({ y }) => !!y),
      }));
    }
    return null;
  }, [rows]);
  const chartRows = useMemo(() => (
    rows?.map((row, i) => ({ id: i, model: row }))
  ), [rows]);
  const totalCampaigns = useMemo(() => (
    (rows?.filter((x) => !x.offer) ?? []).length
  ), [rows]);
  return {
    isLoading,
    stats,
    campaignTypes,
    campaignChartData,
    chartRows,
    rows,
    totalCampaigns,
  };
}
