import { getRelativePosition } from 'chart.js/helpers';

declare module 'chart.js/dist/types/index' {
  interface InteractionModeMap {
    customIndex: InteractionModeFunction;
  }
}

function getDistanceMetricForAxis(axis: any) {
  const useX = axis.indexOf('x') !== -1;
  const useY = axis.indexOf('y') !== -1;

  return (pt1: any, pt2: any) => {
    const deltaX = useX ? Math.abs(pt1.x - pt2.x) : 0;
    const deltaY = useY ? Math.abs(pt1.y - pt2.y) : 0;
    return Math.sqrt(deltaX**2 + deltaY**2);
  };
}

function evaluateInteractionItems(chart: any, axis: any, position: any, handler: any) {
  const metasets = chart.getSortedVisibleDatasetMetas();
  for (let i = 0, ilen = metasets.length; i < ilen; i += 1) {
    const { index, data } = metasets[i];
    const lo = 0;
    const hi = data.length - 1;
    for (let j = lo; j <= hi; j += 1) {
      const element = data[j];
      if (element && !element.skip) {
        handler(element, index, j);
      }
    }
  }
}

function getNearestCartesianItems(
  chart: any,
  position: any,
  axis: any,
  intersect: any,
  useFinalPosition: any,
  includeInvisible: any
) {
  let items: any = [];
  const distanceMetric = getDistanceMetricForAxis(axis);
  let minDistance = Number.POSITIVE_INFINITY;

  function evaluationFunc(element: any, datasetIndex: any, index: any) {
    const inRange = element.inRange(position.x, position.y, useFinalPosition);
    if (intersect && !inRange) {
      return;
    }

    const center = element.getCenterPoint(useFinalPosition);
    const pointInArea = !!includeInvisible || chart.isPointInArea(center);
    if (!pointInArea && !inRange) {
      return;
    }

    const distance = distanceMetric(position, center);
    if (distance < minDistance) {
      items = [{ element, datasetIndex, index }];
      minDistance = distance;
    } else if (distance === minDistance) {
      // Can have multiple items at the same distance in which case we sort by size
      items.push({ element, datasetIndex, index });
    }
  }

  evaluateInteractionItems(chart, axis, position, evaluationFunc);
  return items;
}

function getNearestItems(
  chart: any,
  position: any,
  axis: any,
  intersect: any,
  useFinalPosition: any,
  includeInvisible: any
) {
  if (!includeInvisible && !chart.isPointInArea(position)) {
    return [];
  }

  return getNearestCartesianItems(
    chart,
    position,
    axis,
    intersect,
    useFinalPosition,
    includeInvisible
  );
}

export default (chart: any, e: any, options: any, useFinalPosition: any) => {
  const position = getRelativePosition(e, chart);
  // Default axis for index mode is 'x' to match old behaviour
  const axis = options.axis || 'x';
  const includeInvisible = options.includeInvisible || false;
  const items = getNearestItems(
    chart,
    position,
    axis,
    false,
    useFinalPosition,
    includeInvisible
  );
  const elements: any = [];

  if (!items.length) {
    return [];
  }

  chart.getSortedVisibleDatasetMetas().forEach((meta: any) => {
    const {index} = items[0];
    const element = meta.data[index];

    // don't count items that are skipped (null data)
    if (element && !element.skip) {
      elements.push({ element, datasetIndex: meta.index, index });
    }
  });

  return elements;
};