import React, { useMemo } from 'react';
import { CheckboxElement, DatePickerElement, FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { yupResolver } from '@hookform/resolvers/yup';
import { createDomainAPI, removeDomainAPI, updateDomainAPI } from 'apis/domains';
import { CustomModal } from 'components/CustomModal';
import { FormInput } from 'components/Forms/FormInput';
import { FormSelect } from 'components/Forms/FormSelect';
import dayjs, { Dayjs } from 'dayjs';
import type { IDomain, IProduct } from 'types/domains';
import type { IOrganizationTableRow } from 'types/organizations';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';

import { useUpdateProfile } from '../../../auth';
import { useFullYearFormat } from '../../../utils/format';

type Props = {
  data?: IOrganizationTableRow;
  products: IProduct[];
  updateData: () => void;
};

type FormData = Omit<IDomain, 'id' | 'startDate'> & { startDate: Dayjs | null };

function EditDomainForm({ data, products, updateData }: Props) {
  const fullYearFormat = useFullYearFormat();
  const formContext = useForm<FormData>({
    resolver: yupResolver(yup.object().shape({
      url: yup.string().required('This field is required'),
      name: yup.string().required('This field is required'),
      startDate: yup.date().required('This field is required'),
      timezone: yup.string().required('This field is required'),
      surveyId: yup.string().required('This field is required'),
      prodsId: yup.array().min(1, 'Select at least one product'),
    })),
  });
  const {
    handleSubmit,
    formState: { isDirty },
    setValue,
    reset: formReset,
    control,
  } = formContext;
  const {
    name,
    domainName,
    startDate,
    logoId,
    logoUrl,
    domain,
    prodsId,
    surveyId,
    timezone,
    grossRevViewOnly,
  } = data ?? {};

  const [isReset, setIsReset] = React.useState(0);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = React.useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = React.useState(false);

  const updateProfile = useUpdateProfile();
  const onSubmit = async (formData: FormData) => {
    const newFormData = { ...formData, startDate: dayjs(formData.startDate).format('YYYY-MM-DD') };
    if (data?.domain === undefined) {
      if (typeof data?.id === 'number') {
        const response = await createDomainAPI(data?.id, newFormData);
        if (response) updateData();
        await updateProfile();
      }
    } else if (typeof data?.id === 'string') {
      const ids = data.id.split('-');
      const response = await updateDomainAPI(ids[0], ids[1], newFormData);
      if (response) updateData();
      await updateProfile();
    }
  };

  const onRemove = async (id: string | number) => {
    if (typeof id === 'string') {
      const ids = id.split('-');
      await removeDomainAPI(ids[0], ids[1]);
      updateData();
      await updateProfile();
    }
  };

  React.useEffect(() => {
    formReset({
      url: domain,
      prodsId: prodsId ?? [],
      surveyId, timezone,
      name: domainName,
      startDate: startDate ? dayjs(startDate) : null,
      grossRevViewOnly: grossRevViewOnly ?? false,
    });
  }, [data]);

  const logo = useMemo(() => (
    (logoId && logoUrl) ? { uploadedId: logoId, uploadedUrl: logoUrl } : null
  ), [logoId, logoUrl]);

  return (
    <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)} key={isReset}>
      <Stack spacing="18px">
        <Stack direction="column">
          <Typography variant="subtitle2" textTransform="uppercase">
            {name}
          </Typography>

          {startDate ? (
            <Typography variant="body2">
              Customer since {dayjs(startDate).format('MMM DD, YYYY')}
            </Typography>
          ) : null}
        </Stack>

        <TextFieldElement
          name="url"
          label="Domain URL"
          required
          InputLabelProps={{ required: false }}
        />

        <TextFieldElement
          name="name"
          label="Domain Display Name"
          required
          InputLabelProps={{ required: false }}
        />

        <DatePickerElement
          label='Subscription date'
          name='startDate'
          format={fullYearFormat}
          required
          inputProps={{ InputLabelProps: { required: false } }}
        />

        <FormSelect.MultiSelect
          placeholder="Products"
          name="prodsId"
          options={products.map((product) => ({ id: product.id, label: product.name }))}
          control={control}
          showCheckbox
          showAllItem
          required
        />

        <Stack direction="row" alignItems="center">
          <FormInput.ImageUploader
            name="logo"
            logo={logo}
            setValue={(value) => {
              setValue('logoId', value?.uploadedId ?? null);
              setValue('logoUrl', value?.uploadedUrl ?? null);
            }}
            control={control}
          />
          <Typography variant="body2" marginLeft={3}>
            Recommended image size: 680x340 px. <br />
            File formats: png, jpg
          </Typography>
        </Stack>

        <FormControl>
          <Typography variant="body">Timezone for reporting data</Typography>
          <FormSelect.TimezoneSelect
            name="timezone"
            placeholder="Search for location"
            onInputChange={(value: string) => setValue('timezone', value)}
            required
          />
        </FormControl>

        <TextFieldElement
          name="surveyId"
          label="Survey id"
          required
          InputLabelProps={{ required: false }}
        />

        <Tooltip title="Net new revenue or control group data will not be visible.">
          <Box sx={{ alignSelf: 'flex-start' }}>
            <CheckboxElement
                label="Gross revenue view only"
                name="grossRevViewOnly"
                labelProps={{
                  componentsProps: { typography: { variant: 'body2' } },
                }}
            />
          </Box>
        </Tooltip>

        <Stack justifyContent="space-between" direction="row">
          {data?.domain ? (
            <Button variant="text" color="error" onClick={() => setIsDeleteModalOpened(true)}>
              Delete domain
            </Button>
          ) : (
            <Box />
          )}
          <Box>
            {isDirty ? (
              <Button
                variant="text"
                onClick={() => {
                  formReset({ ...data, url: domain, name: domainName, startDate: startDate ? dayjs(startDate) : null });
                  setIsReset(Math.random());
                }}
              >
                Discard changes
              </Button>
            ) : null}
            <Button
              type="submit"
              variant="contained"
              sx={{ marginX: 2 }}
              disabled={!isDirty}
            >
              Save
            </Button>
          </Box>
        </Stack>
      </Stack>

      {data ? (
        <>
          <CustomModal
            open={isDeleteModalOpened}
            onClose={() => setIsDeleteModalOpened(false)}
            onCancel={() => setIsConfirmModalOpened(true)}
            content={
              <>
                All users assigned to {domain} will lose access to Metrical.
                <br /> <br /> Data for {domain} will become inaccessible.
              </>
            }
            actionButtonLabels={{ cancel: 'delete', confirm: 'cancel' }}
          />
          <CustomModal
            open={isConfirmModalOpened}
            onClose={() => {
              setIsConfirmModalOpened(false);
              setIsDeleteModalOpened(false);
            }}
            onCancel={() => onRemove(data.id)}
            content={`Are you sure you want to delete ${domain}?`}
            actionButtonLabels={{ cancel: 'delete', confirm: 'cancel' }}
          />
        </>
      ) : null}
    </FormContainer>
  );
}

export default EditDomainForm;
