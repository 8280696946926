import axios, { AxiosResponse } from 'axios';

import { ICreateUser, IProfile, IRolesUser } from '../types/users';

import { API_URL } from './config';

export const fetchUserProfile = async (): Promise<AxiosResponse<IProfile>> => {
  const response = await axios.get<IProfile>(`${API_URL}/users/me`);
  const { data } = response;
  const sortedOrgs = [...data?.organizations ?? []].sort(
    (a, b) => a.name.localeCompare(b.name),
  ).map((org) => ({
    ...org,
    domains: org.domains.sort((a, b) => a.url.localeCompare(b.url)),
  }));
  return {
    ...response,
    data: {
      ...data,
      organizations: sortedOrgs,
    },
  };
};

export const updateUserProfile = (data: Omit<IProfile, 'avatarUrl'>): Promise<AxiosResponse<{}>> => (
  axios.put(`${API_URL}/users/me`, data)
);

export const createUser = (data: ICreateUser): Promise<AxiosResponse<{ id: number }>> => (
  axios.post(`${API_URL}/users`, data)
);

export const updateUser = (data: ICreateUser & { id: number }): Promise<AxiosResponse<{}>> => (
  axios.put(`${API_URL}/users/${data.id}`, data)
);

export const deleteUser = (id: number): Promise<AxiosResponse<{}>> => (
  axios.delete(`${API_URL}/users/${id}`)
);

export const listUsers = async (): Promise<AxiosResponse<IRolesUser[]>> => {
  const response = await axios.get(`${API_URL}/users`);
  const { data } = response;
  return {
    ...response,
    data: data.map((x: Omit<IRolesUser, 'onepage'>) => ({
      onepage: false,
      ...x,
    })),
  };
};
