import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Session } from '@supabase/supabase-js';
import dayjs from 'dayjs';
import Router from 'routes';

import { fetchUserProfile } from './apis/users';
import useAsyncEffect from './hooks/useAsyncEffect';
import paths from './routes/paths';
import { IProfile } from './types/users';
import { SessionContext, supabase } from './auth';

import './App.css';

export default function App() {
  const [ready, setReady] = useState(false);
  const [session, setSession] = useState<Session | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    supabase.auth.getSession().then(({ data }) => {
      setSession(data.session);
      setReady(true);
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, newSession) => {
      setSession(newSession);
      if (event === 'PASSWORD_RECOVERY') {
        navigate(paths.auth.resetPassword);
      }
    });
    return () => subscription.unsubscribe();
  }, []);
  const [profile, setProfile] = useState<IProfile | null>(null);
  const [profileReady, setProfileReady] = useState(false);
  useAsyncEffect(async () => {
    if (ready && !profile) {
      if (session) {
        setProfileReady(false);
        try {
          const { data } = await fetchUserProfile();
          setProfile(data);
        } catch { /* ignore 401 */ }
      }
      setProfileReady(true);
    }
  }, [session, ready, profile]);
  useEffect(() => {
    if (profile) {
      Sentry.setUser({ email: profile.email });
      dayjs.updateLocale('en', {
        weekStart: profile.firstWeekday === 'sunday' ? 0 : 1,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [profile]);
  const contextValue = useMemo(() => ({ session, profile, setProfile }), [session, profile]);
  if (!ready || !profileReady) {
    return null;
  }
  return (
    <SessionContext.Provider value={contextValue}>
      <Router />
    </SessionContext.Provider>
  );
}
