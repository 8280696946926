import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CanceledError } from 'axios';
import { Chart as ChartJS } from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import {
  DashboardType,
  IAggregatedStats,
  ICampaignOfferTrend,
  ICampaignOrOffer,
  IGrossRow,
  IIncrementalRow,
  ITrendWithProducts
} from 'types/global';

import { useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import styled from '@mui/system/styled';
import { GridFilterModel, GridLogicOperator, useGridApiRef } from '@mui/x-data-grid-pro';
import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internal/models';

import { getCampaignsAndOffers, getChartData, getKPI } from '../apis/dashboard';
import { SessionContext } from '../auth';
import ChartContainer from '../components/DataGrid/ChartContainer';
import DashboardTable from '../components/DataGrid/DashboardTable';
import DateRangePicker from '../components/DateRangePicker';
import MiscFilters from '../components/MiscFilters';
import PDFDownload from '../components/PDFDownload';
import Share from '../components/Share';
import useAsyncEffect from '../hooks/useAsyncEffect';
import useUpdateEffect from '../hooks/useUpdateEffect';
import { HeaderContext } from '../layouts/main/header/context';
import { IProfileDomain } from '../types/users';
import {
  QueryParamsContext,
  useQueryState,
  useQueryStateCampaignOrOfferList,
  useQueryStateDateRange
} from '../utils/QueryParamsContext';

import DashboardContext, { DashboardContextValue } from './DashboardContext';
import { useTrendColors } from './dashboardHooks';

const OptionOffer = styled('span')({
  fontWeight: 800,
});

const OptionCampaign = styled('span')(({ theme }) => ({
  fontWeight: 800,
  color: theme.palette.secondary.dark,
}));

const Option = styled('li')(({ theme }) => ({
  '& > span:not(:last-child)::after': {
    display: 'inline',
    content: '"/"',
    margin: '0 4px',
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
}));

interface DashboardProps<R extends IIncrementalRow | IGrossRow> {
  dashboardType: R extends IIncrementalRow ? DashboardType.Incremental : DashboardType.Gross;
}

function Dashboard<R extends IIncrementalRow | IGrossRow>({ dashboardType }: DashboardProps<R>) {
  window.sessionStorage.removeItem('after-login-path');
  const theme = useTheme();
  const { profile } = useContext(SessionContext);
  const { domainId, organizationId } = useContext(HeaderContext);
  const [rows, setRows] = useState<R[] | null>(null);
  const [searchOptions, setSearchOptions] = useState<ICampaignOrOffer[]>([]);
  useAsyncEffect(async () => {
    const { data } = await getCampaignsAndOffers(organizationId);
    setSearchOptions(data);
  }, [organizationId]);
  const [filteredOffers, setFilteredOffers] = useQueryStateCampaignOrOfferList('filtered-offers', []);
  const [searchValue, setSearchValue] = useState<ICampaignOrOffer[]>(filteredOffers);
  const [filterModel, setFilterModel] = useQueryState<GridFilterModel>('filter-model', {
    items: [],
    logicOperator: GridLogicOperator.And,
  });
  const today = dayjs().startOf('day');
  const [dateRange, setDateRange] = useQueryStateDateRange('date-range', [
    dayjs(today).subtract(30, 'days'),
    today,
  ]);
  // const [visitorsChecked, setVisitorsChecked] = useState<Record<string, boolean>>({
  //   firstPurchase: false,
  //   repeatPurchaser: false,
  //   rewardsMember: false,
  // });
  const [selectedOffers, setSelectedOffers] = useQueryState<R[] | null>('selected-offers', null);
  const [chartData, setChartData] = useState<ICampaignOfferTrend[] | null>(null);
  useLayoutEffect(() => {
    setRows(null);
  }, [filteredOffers, dateRange]);
  const chartMinDate = (filteredOffers.length > 0 && (rows?.length || 0) > 0 && chartData && chartData.length > 0)
    ? _.minBy(chartData.flatMap((x) => x.points), (x) => dayjs(x.date).valueOf())!.date
    : dateRange[0]!.format('YYYY-MM-DD');
  const chartMaxDate = (filteredOffers.length > 0 && (rows?.length || 0) > 0 && chartData && chartData.length > 0)
    ? _.maxBy(chartData.flatMap((x) => x.points), (x) => dayjs(x.date).valueOf())!.date
    : dateRange[1]!.format('YYYY-MM-DD');
  const [dataDashboardType, setDataDashboardType] = useState<DashboardType>(dashboardType);
  const prevDomainId = useRef(domainId);
  const firstRender = useRef(true);
  const getKPIAbortController = useRef<AbortController | null>(null);
  const getChartDataAbortController = useRef<AbortController | null>(null);
  useAsyncEffect(async () => {
    const type = dashboardType === DashboardType.Incremental ? 'incremental' : 'gross' as any;
    if (domainId === prevDomainId.current) {
      if (filteredOffers.length > 0) {
        if (getKPIAbortController.current) {
          getKPIAbortController.current.abort();
        }
        if (getChartDataAbortController.current) {
          getChartDataAbortController.current.abort();
        }
        getKPIAbortController.current = new AbortController();
        getChartDataAbortController.current = new AbortController();
        try {
          const [
            { data },
            { data: newChartData },
          ] = await Promise.all([
            getKPI({ filter: filteredOffers, type }, profile!.organizations, getKPIAbortController.current.signal),
            getChartData({ filter: filteredOffers, type }, profile!.organizations, getChartDataAbortController.current.signal),
          ]);
          setRows(data as any);
          setChartData(newChartData);
          setDataDashboardType(dashboardType);
          if (firstRender.current) {
            firstRender.current = false;
          } else {
            setSelectedOffers(null);
          }
        } catch (e) {
          if (!(e instanceof CanceledError)) {
            throw e;
          }
        }
      }
    } else {
      setSearchValue([]);
      setFilteredOffers([]);
    }
    prevDomainId.current = domainId;
  }, [filteredOffers, dashboardType, domainId, profile!.organizations]);
  useAsyncEffect(async () => {
    const type = dashboardType === DashboardType.Incremental ? 'incremental' : 'gross' as any;
    if (filteredOffers.length === 0 && dateRange[0] && dateRange[1]) {
      if (getKPIAbortController.current) {
        getKPIAbortController.current.abort();
      }
      if (getChartDataAbortController.current) {
        getChartDataAbortController.current.abort();
      }
      getKPIAbortController.current = new AbortController();
      getChartDataAbortController.current = new AbortController();
      try {
        const [
          { data },
          { data: newChartData },
        ] = await Promise.all([
          getKPI({
            domain: domainId,
            startDate: dateRange[0].format('YYYY-MM-DD'),
            endDate: dateRange[1].format('YYYY-MM-DD'),
            type,
          }, profile!.organizations, getKPIAbortController.current.signal),
          getChartData({ domain: domainId, startDate: chartMinDate, endDate: chartMaxDate, type }, profile!.organizations, getChartDataAbortController.current.signal),
        ]);
        setRows(data as any);
        setChartData(newChartData);
        setDataDashboardType(dashboardType);
        if (firstRender.current) {
          firstRender.current = false;
        } else {
          setSelectedOffers(null);
        }
      } catch (e) {
        if (!(e instanceof CanceledError)) {
          throw e;
        }
      }
    }
  }, [dateRange, domainId, filteredOffers, chartMinDate, chartMaxDate, dashboardType, profile!.organizations]);
  const { clearParams } = useContext(QueryParamsContext);
  useUpdateEffect(() => {
    clearParams();
  }, [dateRange, filteredOffers, dashboardType, clearParams]);
  const [aggregatedStats, setAggregatedStats] = useState<IAggregatedStats | null>(null);
  const chartRef = useRef<ChartJS>(null);
  const [hoveredTrend, setHoveredTrend] = useState<string | null>(null);
  const [trendColors, setTrendColors] = useTrendColors(rows);
  const apiRef = useGridApiRef();
  const [, triggerUpdate] = useState(0);
  useEffect(() => {
    triggerUpdate(Math.random());
  }, [rows]);
  const [deviceColumnHidden, setDeviceColumnHidden] = useState(false);
  const contextValue = useMemo((): DashboardContextValue => ({
    dateRange: filteredOffers.length > 0 ? [null, null] : dateRange,
    hoveredTrend,
    setHoveredTrend,
    trendColors,
    setTrendColors,
    apiRef,
  }), [dateRange, filteredOffers, hoveredTrend, trendColors]);
  if (!profile) {
    return null;
  }
  const chartDataWithProducts: ITrendWithProducts[] | null = useMemo(
    () => (rows && chartData) ? chartData.map((trend) => {
      const { products } = rows.find((row) => (
        ('offer' in trend ? row.offer === trend.offer : true)
        && row.campaign === trend.campaign
      )) as R;
      const points = trend.points.map((point) => ({
        ...point,
        ...(products.includes('cs') ? { cartCreationChange: undefined } : {}),
      }));
      return { ...trend, products, points };
    }) : null,
    [rows, chartData],
  );
  const performanceChartData = useMemo(() => {
    if (rows) {
      return rows.filter((x) => !x.offer).map((row) => ({
        x: row.campaign,
        y: dashboardType === DashboardType.Incremental
          ? (row as IIncrementalRow).incrementalRevenue
          : (row as IGrossRow).grossRevenue,
      }));
    }
    return null;
  }, [rows, dashboardType]);
  const displayDateRange: NonEmptyDateRange<Dayjs> = filteredOffers.length > 0 ? [dayjs(chartMinDate), dayjs(chartMaxDate)] : dateRange;
  const singleDomain = profile.organizations.length === 1 && profile.organizations[0].domains.length === 1;
  return (
    <DashboardContext.Provider value={contextValue}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: theme.palette.secondary.lighter,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            margin: { xs: '8px 0', lg: '16px 0 28px' },
          }}
        >
          <Autocomplete
            multiple
            value={searchValue}
            onChange={(_event, value, reason) => {
              setSearchValue(value);
              if (reason === 'clear') {
                setFilteredOffers([]);
              }
            }}
            options={searchOptions}
            groupBy={(option) => 'offer' in option ? 'Offers' : 'Campaigns'}
            getOptionLabel={(option) => {
              const domain = profile.organizations.flatMap((x) => x.domains)
                .find((x) => x.id === option.domain) as IProfileDomain;
              const offer = 'offer' in option ? `${option.offer} / ` : '';
              const keywords = option.keywords ? `${option.keywords.join(', ')} / ` : '';
              return `${offer}${keywords}${option.campaign}${singleDomain ? '' : ` / ${domain.url}`}`;
            }}
            renderOption={(props, option) => {
              const domain = profile.organizations.flatMap((x) => x.domains)
                .find((x) => x.id === option.domain) as IProfileDomain;
              return (
                <Option {...props}>
                  {'offer' in option && (
                    <OptionOffer>
                      {option.offer}
                    </OptionOffer>
                  )}
                  {option.keywords && (
                    <OptionOffer>
                      {option.keywords.join(', ')}
                    </OptionOffer>
                  )}
                  <OptionCampaign>
                    {option.campaign}
                  </OptionCampaign>
                  {!singleDomain && (
                    <span>
                      {domain.url}
                    </span>
                  )}
                </Option>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  searchValue.length > 0 ? undefined : 'Search and select campaigns or offers'
                }
              />
            )}
            filterOptions={(options, { inputValue }) => options.filter((option) => (
              inputValue.split(/\s+/).every((word) => (
                (option.offer || option.campaign).toLowerCase().includes(word.toLowerCase()
              )))
            ))}
            isOptionEqualToValue={(option, value) => _.isEqual(option, value)}
            sx={{ flex: 1, display: { xs: 'none', lg: 'block' } }}
          />
          <Button
            variant="contained"
            size="large"
            sx={{ marginLeft: '8px', display: { xs: 'none', lg: 'block' } }}
            onClick={() => setFilteredOffers(searchValue)}
          >
            View Selected
          </Button>
          <MiscFilters
            filterModel={filterModel}
            setFilterModel={setFilterModel}
            deviceColumnHidden={deviceColumnHidden}
            // visitorsChecked={visitorsChecked}
            // setVisitorsChecked={setVisitorsChecked}
          />
          <DateRangePicker
            value={displayDateRange}
            onChange={setDateRange}
            TextFieldProps={{
              sx: { marginLeft: 1.75, width: 244 }, disabled: filteredOffers.length > 0,
              'aria-label': 'Select date range',
            }}
          />
          <PDFDownload
            dateRange={displayDateRange}
            aggregatedStats={aggregatedStats}
            chartRef={chartRef}
            dashboardType={dataDashboardType}
            apiRef={apiRef}
            selectedOffers={selectedOffers}
          />
          <Share />
        </Box>
        {chartDataWithProducts && performanceChartData && apiRef?.current?.state && (
          <ChartContainer
            selectedOffers={selectedOffers}
            minDate={chartMinDate}
            maxDate={chartMaxDate}
            filteredOffers={filteredOffers}
            chartData={chartDataWithProducts}
            setAggregatedStats={setAggregatedStats}
            chartRef={chartRef}
            dashboardType={dataDashboardType}
            performanceChartData={performanceChartData}
          />
        )}
        <DashboardTable
          rows={rows}
          selectedOffers={selectedOffers}
          setSelectedOffers={setSelectedOffers}
          filteredOffers={filteredOffers}
          filterModel={filterModel}
          setFilterModel={setFilterModel}
          dashboardType={dataDashboardType as any}
          dateRange={displayDateRange}
          apiRef={apiRef}
          setDeviceColumnHidden={setDeviceColumnHidden}
        />
      </Box>
    </DashboardContext.Provider>
  );
}

function WrappedDashboard() {
  const { dashboardType } = useContext(HeaderContext);
  return (
    <Dashboard dashboardType={dashboardType} />
  );
}

export default WrappedDashboard;
