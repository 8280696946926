import React, { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { wrapUseRoutesV6 } from '@sentry/react';
import MainLayout, { MainLayoutProps } from 'layouts/main';
import ManageOrganizationPage from 'pages/accounts/Organization';
import UserRolesPage from 'pages/accounts/Roles';
import Register from 'pages/auth/Register';
import CustomerOrganizationPage from 'pages/customer/CustomerOrganization';
import Dashboard from 'pages/Dashboard';
import Profile from 'pages/Profile';

import { SessionContext } from '../auth';
import AuthLayout from '../layouts/auth';
import AccessPending from '../pages/auth/AccessPending';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Login from '../pages/auth/Login';
import ResetPassword from '../pages/auth/ResetPassword';
import Exec from '../pages/exec/Exec';
import ShortLink from '../pages/ShortLink';
import { QueryParamsProvider } from '../utils/QueryParamsContext';

import PATH_NAMES from './paths';

const useSentryRoutes = wrapUseRoutesV6(useRoutes);

function Redirect() {
  const path = window.sessionStorage.getItem('after-login-path');
  if (path) {
    return <Navigate to={path} />;
  }
  return <Navigate to="/dashboard" replace />;
}

function RegularUserMainLayout(props: MainLayoutProps) {
  const { profile } = useContext(SessionContext);
  if (profile?.onepage) {
    return <Navigate to={PATH_NAMES.exec.root} replace />;
  }
  return <MainLayout {...props} />;
}

export default function Router() {
  return useSentryRoutes([
    {
      path: PATH_NAMES.dashboard.root,
      element: (
        <QueryParamsProvider>
          <RegularUserMainLayout isDashboard />
        </QueryParamsProvider>
      ),
      children: [{ element: <Dashboard />, index: true }],
    },
    {
      path: PATH_NAMES.exec.root,
      element: (
        <QueryParamsProvider>
          <MainLayout isDashboard />
        </QueryParamsProvider>
      ),
      children: [{ element: <Exec />, index: true }],
    },
    {
      path: PATH_NAMES.accounts.root,
      element: <RegularUserMainLayout />,
      children: [
        {
          element: <Navigate to={PATH_NAMES.accounts.roles} replace />,
          index: true,
        },
        { path: PATH_NAMES.accounts.roles, element: <UserRolesPage /> },
        {
          path: PATH_NAMES.accounts.organization,
          element: <ManageOrganizationPage />,
        },
      ],
    },
    {
      path: PATH_NAMES.customer.root,
      element: <RegularUserMainLayout />,
      children: [
        {
          element: <Navigate to={PATH_NAMES.customer.organization} replace />,
          index: true,
        },
        {
          path: PATH_NAMES.customer.organization,
          element: <CustomerOrganizationPage />,
        },
      ],
    },
    {
      path: PATH_NAMES.profile.root,
      element: <MainLayout />,
      children: [
        // {
        //   element: <Navigate to="" replace />,
        //   index: true,
        // },
        {
          path: '',
          element: <Profile />,
        },
      ],
    },
    {
      path: PATH_NAMES.auth.root,
      element: <AuthLayout />,
      children: [
        {
          element: <Navigate to={PATH_NAMES.auth.login} replace />,
          index: true,
        },
        { path: PATH_NAMES.auth.login, element: <Login /> },
        { path: PATH_NAMES.auth.register, element: <Register /> },
        { path: PATH_NAMES.auth.forgotPassword, element: <ForgotPassword /> },
        { path: PATH_NAMES.auth.resetPassword, element: <ResetPassword /> },
        { path: PATH_NAMES.auth.accessPending, element: <AccessPending /> },
      ],
    },
    {
      path: PATH_NAMES.shortLink.root,
      element: <MainLayout />,
      children: [{ element: <ShortLink />, index: true }],
    },
    { path: '*', element: <Redirect /> },
  ]);
}
