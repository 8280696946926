import React from 'react';

import Box from '@mui/material/Box';

export default function StatElement({ label, value }: { label: string, value: string | number }) {
  return (
    <Box
      sx={{
        padding: '10px 0 7px',
        borderBottom: '1px solid #D6D6D6',
        color: '#4559A7',
        '&:last-of-type': {
          borderBottom: 'none',
        },
      }}
    >
      <Box
        sx={{
          fontSize: '12px',
          lineHeight: '14px',
          textTransform: 'lowercase',
          fontVariant: 'small-caps',
        }}
      >
        {label}
      </Box>
      <Box
        sx={{
          fontSize: '32px',
          lineHeight: '40px',
          letterSpacing: '0.25px',
        }}
      >
        {value}
      </Box>
    </Box>
  )
}
