import React from 'react';
import {
  Document, Image, Page, StyleSheet, Text,
View, } from '@react-pdf/renderer';
import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg';
import { Chart as ChartJS } from 'chart.js';
import { Dayjs } from 'dayjs';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internal/models';

import { shortenLink } from '../../apis/shortLinks';
import {
  Footer,
  Header,
  HeaderProps,
  makeChartImage,
  useDownloadPdf,
  useHeaderProps
} from '../../components/pdfCommon';
import { makeFormatCurrency, makeFormatNumber } from '../../utils/format';
import { useQuery } from '../../utils/QueryParamsContext';

interface PDFDownloadProps {
  dateRange: NonEmptyDateRange<Dayjs> | null;
  stats: Record<string, number>;
  countChartRef: React.RefObject<ChartJS>;
  revenueChartRef: React.RefObject<ChartJS>;
  rpvChartRef: React.RefObject<ChartJS>;
  bottomChartRef: React.RefObject<ChartJS>;
  totalCampaigns: number;
}

interface ReportProps {
  headerProps: HeaderProps;
  countChartImage: string;
  revenueChartImage: string;
  rpvChartImage: string;
  bottomChartImage: string;
}

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Soleil',
    padding: '21pt 30pt 6pt 30pt',
  },
  chartImage: {
    margin: '5pt 0',
    width: '100%',
  },
  main: {
    flexDirection: 'row',
    margin: '40pt 0 20pt',
  },
  statBorder: {
    borderBottom: '1px solid #D6D6D6',
  },
  statLabel: {
    fontSize: '7pt',
    padding: '3pt 0 0',
    textTransform: 'uppercase',
    color: '#4559A7',
  },
  statValue: {
    fontSize: '17pt',
    paddingBottom: '2pt',
    color: '#4559A7',
  },
  chartContainer: {
    border: '1pt solid #D6D6D6',
    marginLeft: '9pt',
  },
  chartHeader: {
    backgroundColor: '#F5F3F1',
    borderBottom: '1pt solid #D6D6D6',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 11pt 2.5pt',
    height: '36pt',
  },
  smallChartImage: {
    width: '193pt',
    padding: '4pt',
  },
  chartHeaderText: {
    fontSize: '7pt',
    paddingTop: '2.5pt',
    textTransform: 'uppercase',
    color: '#4559A7',
  },
  chartHeaderNumber: {
    fontSize: '12pt',
    fontWeight: 'extrabold',
    color: '#4559A7',
    marginLeft: '4pt',
  },
});

interface StatProps {
  label: string;
  value: string;
  isLast?: boolean;
}
function Stat({ label, value, isLast }: StatProps) {
  return (
    <View style={isLast ? {} : styles.statBorder}>
      <Text style={styles.statLabel}>
        {label}
      </Text>
      <Text style={styles.statValue}>
        {value}
      </Text>
    </View>
  );
}

interface ChartContainerProps {
  header: string;
  headerNumber?: string;
  chart: string;
}
function ChartContainer({ header, headerNumber, chart }: ChartContainerProps) {
  return (
    <View style={styles.chartContainer}>
      <View style={styles.chartHeader}>
        <Text style={styles.chartHeaderText}>
          {header}
        </Text>
        <Text style={styles.chartHeaderNumber}>
          {headerNumber}
        </Text>
      </View>
      <Image
        src={chart}
        style={styles.smallChartImage}
      />
    </View>
  );
}

function Report(
  {
    headerProps,
    stats,
    countChartImage,
    revenueChartImage,
    rpvChartImage,
    bottomChartImage,
    totalCampaigns,
  }: ReportProps & Pick<PDFDownloadProps, 'stats' | 'totalCampaigns'>,
) {
  const { profile } = headerProps;
  const formatNumber = makeFormatNumber(profile);
  const formatCurrency = makeFormatCurrency(profile);
  const formatCurrency3 = makeFormatCurrency(profile, 3);
  return (
    <Document>
      <Page size="LETTER" orientation="landscape" style={styles.page} wrap>
        <Header {...headerProps} />
        <View style={styles.main}>
          <View>
            <Stat
              label="Metrical engaged"
              value={`${stats.engagedPercent}%`}
            />
            <Stat
              label="Visitors engaged by Metrical"
              value={formatNumber({ value: stats.visitorsEngaged })}
            />
            <Stat
              label="Net new revenue"
              value={formatCurrency({ value: stats.netNewRevenue })}
            />
            <Stat
              label="RPV"
              value={formatCurrency3({ value: stats.rpv })}
            />
            <Stat
              label="Conversion rate % change"
              value={`${stats.conversionChange}%`}
              isLast
            />
          </View>
          <ChartContainer header="Campaigns run" headerNumber={`${totalCampaigns}`} chart={countChartImage} />
          <ChartContainer header="New new revenue by campaign types" chart={revenueChartImage} />
          <ChartContainer header="RPV" chart={rpvChartImage} />
        </View>
        <Image
          src={bottomChartImage}
          style={styles.chartImage}
        />
        <Footer />
      </Page>
    </Document>
  );
}

export default function PDFDownload(props: PDFDownloadProps) {
  const {
    dateRange,
    ...rest
  } = props;
  const { countChartRef, revenueChartRef, rpvChartRef, bottomChartRef } = props;
  const headerProps = useHeaderProps();
  const disabled = !headerProps;
  const query = useQuery();
  const downloadPdf = useDownloadPdf();
  const button = (
    <IconButton
      sx={{
        margin: '-5px -9px -5px 4px',
        display: { xs: 'none', lg: 'inline-flex' },
      }}
      onClick={async () => {
        if (!headerProps || !dateRange) {
          return;
        }
        const smallChartSize = {
          width: 363,
          height: 288,
        };
        const [
          countChartImage, revenueChartImage, rpvChartImage, bottomChartImage,
        ] = await Promise.all([
          makeChartImage(countChartRef.current as ChartJS, smallChartSize),
          makeChartImage(revenueChartRef.current as ChartJS, smallChartSize),
          makeChartImage(rpvChartRef.current as ChartJS, smallChartSize),
          makeChartImage(bottomChartRef.current as ChartJS, { width: 1464, height: 324 }),
        ]);
        const short = await shortenLink(`exec?${query}`);
        await downloadPdf(
          <Report
            countChartImage={countChartImage}
            revenueChartImage={revenueChartImage}
            rpvChartImage={rpvChartImage}
            bottomChartImage={bottomChartImage}
            headerProps={{
              ...headerProps,
              dateRange,
              shortLink: short,
            }}
            {...rest}
          />,
          dateRange,
        );
      }}
      disabled={disabled}
    >
      <PDFIcon />
    </IconButton>
  );
  return disabled ? button : (
    <Tooltip title="Download PDF report">
      {button}
    </Tooltip>
  );
}
