const ROOT_PATHS = {
  DASHBOARD: '/dashboard',
  EXEC: '/exec',
  ACCOUNTS: '/accounts',
  CUSTOMER: '/customer',
  PROFILE: '/profile',
  AUTH: '/auth',
  SHORT_LINK: '/s/:code',
};

const PATH_NAMES = {
  profile: {
    root: ROOT_PATHS.PROFILE,
  },
  dashboard: {
    root: ROOT_PATHS.DASHBOARD,
  },
  exec: {
    root: ROOT_PATHS.EXEC,
  },
  accounts: {
    root: ROOT_PATHS.ACCOUNTS,
    roles: `${ROOT_PATHS.ACCOUNTS}/roles`,
    organization: `${ROOT_PATHS.ACCOUNTS}/organizations`,
  },
  customer: {
    root: ROOT_PATHS.CUSTOMER,
    organization: `${ROOT_PATHS.CUSTOMER}/organization`,
  },
  auth: {
    root: ROOT_PATHS.AUTH,
    login: `${ROOT_PATHS.AUTH}/login`,
    register: `${ROOT_PATHS.AUTH}/register`,
    forgotPassword: `${ROOT_PATHS.AUTH}/forgot-password`,
    resetPassword: `${ROOT_PATHS.AUTH}/reset-password`,
    accessPending: `${ROOT_PATHS.AUTH}/access-pending`,
  },
  shortLink: {
    root: ROOT_PATHS.SHORT_LINK,
  },
};

export default PATH_NAMES;
