import React, { useContext, useEffect, useRef, useState } from 'react';
import { CheckboxElement, FormContainer, useForm } from 'react-hook-form-mui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { CustomModal } from 'components/CustomModal';
import CustomDataGrid from 'components/DataGrid';
import { booleanOperators, listOperators, stringOperators } from 'components/FilterPanel/operators';
import { FormSelect } from 'components/Forms/FormSelect';
import * as _ from 'lodash';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid-pro';

import { deleteUser, updateUser } from '../../../../apis/users';
import { SessionContext } from '../../../../auth';
import { IProfile, IProfileDomain, IProfileOrganization, IRolesUser } from '../../../../types/users';

import TableActionBar from './TableActionBar';

type Props = {
  users: IRolesUser[];
  updateUsers: React.Dispatch<React.SetStateAction<IRolesUser[] | null>>;
};

export default function UserTableForm({ users, updateUsers }: Props) {
  const { profile } = useContext(SessionContext) as { profile: IProfile };
  const isMetrical = profile.organizations.some((x) => x.master);
  const metricalId = profile.organizations.find((x) => x.master)?.id;
  const organizations = profile.organizations.filter((x) => !x.master);
  const allDomains = organizations.flatMap((x) => x.domains);
  const defaultFormValues = Object.assign({}, ...users.map((user) => ({
    [`firstName_${user.id}`]: user.firstName,
    [`lastName_${user.id}`]: user.lastName,
    [`email_${user.id}`]: user.email,
    [`reports_${user.id}`]: user.reports,
    [`onepage_${user.id}`]: user.onepage,
    [`admin_${user.id}`]: user.admin,
    [`master_${user.id}`]: user.organizations.some((x) => x.master),
    [`organization_${user.id}`]: user.organizations.map((x) => x.id),
    [`domain_${user.id}`]: user.domains.map((x) => x.id),
  })));
  const formContext = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(yup.object().shape(Object.assign({}, ...users.map((user) => ({
      [`email_${user.id}`]: yup.string().required('This field is required').email('Invalid email address'),
      [`domain_${user.id}`]: yup.array().when([`master_${user.id}`], {
        is: false,
        then: (schema) => schema.min(1, 'Select at least one domain'),
      }),
    }))))),
  });
  const {
    control,
    watch,
    setValue,
    getValues,
    reset,
    handleSubmit,
  } = formContext;
  useEffect(() => {
    reset(defaultFormValues);
  }, [users]);

  const [organizationId, setOrganizationId] = useState<string | number | undefined>();
  const [isMultiOrgs, setIsMultiOrgs] = useState(false);
  const [deletingUser, setDeletingUser] = useState<IRolesUser | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isMultiModal, setIsMultiModal] = useState(false);

  const watchOrgs: number[] = watch(`organization_${organizationId}`);
  const nonMetricalOrgs = watchOrgs?.filter((x) => x !== metricalId);

  React.useEffect(() => {
    if (nonMetricalOrgs) {
      if (nonMetricalOrgs.length > 1) {
        setIsMultiOrgs(true);
      } else {
        setIsMultiOrgs(false);
      }
    }
  }, [nonMetricalOrgs]);

  React.useEffect(() => {
    setIsMultiModal(isMultiOrgs);
  }, [isMultiOrgs]);

  const orgState = users.map((user) => [user.id, watch(`organization_${user.id}`)]);
  const prevOrgState = useRef(_.cloneDeep(orgState));
  useEffect(() => {
    const values = getValues();
    orgState.forEach(([id, orgs]) => {
      if (!orgs) {
        return;
      }
      const prevOrgs = prevOrgState.current.find(([id2]) => id2 === id)![1];
      if (!_.isEqual(orgs, prevOrgs)) {
        setOrganizationId(id);
      }
      const orgDomains: number[] = orgs.flatMap((x: number) => (
        organizations.find((y) => y.id === x)?.domains || []
      )).map((x: IProfileDomain) => x.id);
      const currentValue = values[`domain_${id}`];
      if (currentValue) {
        const newValue = currentValue.filter((x: number) => (
          orgDomains.includes(x)
        ));
        if (!_.isEqual(newValue, currentValue)) {
          setValue(`domain_${id}`, newValue);
        }
      }
    });
    prevOrgState.current = _.cloneDeep(orgState);
  }, [orgState, getValues]);
  const masterState = watch(users.map((user) => `master_${user.id}`));
  const adminState = watch(users.map((user) => `admin_${user.id}`));
  const prevMasterState = useRef(masterState);
  const prevAdminState = useRef(adminState);
  useEffect(() => {
    users.forEach(({ id }, i) => {
      const master = masterState[i];
      const admin = adminState[i];
      if (master && admin) {
        if (getValues()[`domain_${id}`]?.length > 0) {
          setValue(`domain_${id}`, []);
        }
        if (getValues()[`organization_${id}`]?.length > 0) {
          setValue(`organization_${id}`, []);
        }
      }
    });
  }, [masterState, adminState, users]);
  const reportsState = watch(users.map((user) => `reports_${user.id}`));
  const onepageState = watch(users.map((user) => `onepage_${user.id}`));
  const prevReportsState = useRef(reportsState);
  const prevOnepageState = useRef(onepageState);
  useEffect(() => {
    reportsState.forEach((reports, i) => {
      const { id } = users[i];
      const prevReports = prevReportsState.current[i];
      if (reports !== prevReports && reports) {
        if (getValues(`master_${id}`)) {
          setValue(`master_${id}`, false);
        }
        if (getValues(`admin_${id}`)) {
          setValue(`admin_${id}`, false);
        }
        if (getValues(`onepage_${id}`)) {
          setValue(`onepage_${id}`, false);
        }
      }
    });
    prevReportsState.current = reportsState;
  }, [reportsState, users]);
  useEffect(() => {
    onepageState.forEach((onepage, i) => {
      const { id } = users[i];
      const prevOnepage = prevOnepageState.current[i];
      if (onepage !== prevOnepage && onepage) {
        if (getValues(`master_${id}`)) {
          setValue(`master_${id}`, false);
        }
        if (getValues(`admin_${id}`)) {
          setValue(`admin_${id}`, false);
        }
        if (getValues(`reports_${id}`)) {
          setValue(`reports_${id}`, false);
        }
      }
    });
    prevOnepageState.current = onepageState;
  }, [onepageState, users]);
  useEffect(() => {
    users.forEach(({ id }, i) => {
      const master = masterState[i];
      const admin = adminState[i];
      const prevMaster = prevMasterState.current[i];
      const prevAdmin = prevAdminState.current[i];
      if ((master !== prevMaster || admin !== prevAdmin) && (master || admin)) {
        if (getValues(`reports_${id}`)) {
          setValue(`reports_${id}`, false);
        }
        if (getValues(`onepage_${id}`)) {
          setValue(`onepage_${id}`, false);
        }
      }
    });
    prevMasterState.current = masterState;
    prevAdminState.current = adminState;
  }, [masterState, adminState, users]);

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'FIRST NAME',
      type: 'string',
      minWidth: 150,
      editable: true,
      filterOperators: stringOperators,
      valueGetter: ({ row }) => getValues()[`firstName_${row.id}`],
      valueSetter: ({ value, row }) => {
        setTimeout(() => {
          if (value !== getValues()[`firstName_${row.id}`]) {
            setValue(`firstName_${row.id}`, value);
          }
        }, 1);
        return row;
      },
    },
    {
      field: 'lastName',
      headerName: 'LAST NAME',
      type: 'string',
      minWidth: 150,
      editable: true,
      filterOperators: stringOperators,
      valueGetter: ({ row }) => getValues()[`lastName_${row.id}`],
      valueSetter: ({ value, row }) => {
        setTimeout(() => {
          if (value !== getValues()[`lastName_${row.id}`]) {
            setValue(`lastName_${row.id}`, value);
          }
        }, 1);
        return row;
      },
    },
    {
      field: 'email',
      headerName: 'EMAIL',
      width: 200,
      editable: true,
      filterOperators: stringOperators,
      valueGetter: ({ row }) => getValues()[`email_${row.id}`],
      valueSetter: ({ value, row }) => {
        setTimeout(() => {
          if (value !== getValues()[`email_${row.id}`]) {
            setValue(`email_${row.id}`, value);
          }
        }, 1);
        return row;
      },
      preProcessEditCellProps: (params) => ({
        ...params.props,
        error: !yup.string().email().isValidSync(params.props.value),
      }),
    },
    {
      field: 'admin',
      headerName: 'ADMIN',
      renderCell: (params) => (
        <CheckboxElement name={`admin_${params.row.id}`} control={control} size="small" />
      ),
      editable: false,
      type: 'boolean',
      filterOperators: booleanOperators,
    },
    {
      field: 'master',
      headerName: 'Metrical associate',
      valueGetter: ({ row }) => getValues()[`master_${row.id}`],
      renderCell: (params) => (
        <CheckboxElement name={`master_${params.row.id}`} control={control} size="small" />
      ),
      editable: false,
      type: 'boolean',
      filterOperators: booleanOperators,
    },
    {
      field: 'onepage',
      headerName: 'Exec Page only',
      renderCell: (params) => (
        <CheckboxElement name={`onepage_${params.row.id}`} control={control} size="small" aria-label="Exec Page only" />
      ),
      editable: false,
      type: 'boolean',
      filterOperators: booleanOperators,
    },
    {
      field: 'reports',
      headerName: 'Email Reports Only',
      renderCell: (params) => (
        <CheckboxElement name={`reports_${params.row.id}`} control={control} size="small" aria-label="Reports only" />
      ),
      editable: false,
      type: 'boolean',
      filterOperators: booleanOperators,
    },
    {
      field: 'organizations',
      headerName: 'ORGANIZATIONS',
      width: 300,
      renderCell: (params) => !(getValues()[`master_${params.row.id}`] && getValues()[`admin_${params.row.id}`]) && (
        <Box component="label" width="100%">
          <FormSelect.MultiSelect
            name={`organization_${params.row.id}`}
            options={_.sortBy(organizations.map((org) => ({ id: org.id, label: org.name })), 'label')}
            control={control}
            formControlSx={{ width: '100%' }}
            selectSx={{
              boxShadow: 'none',
              backgroundColor: 'white',
              border: 0,
              '& .MuiSelect-select': {
                padding: '9px 40px 9px 4px !important',
              },
            }}
            showCheckbox
            showAllItem
          />
        </Box>
      ),
      editable: false,
      valueGetter: ({ row }) =>
        _.sortBy(
          organizations.filter(({ id }) => (getValues()[`organization_${row.id}`] || []).includes(id)),
          'name'
        ).map((x: IProfileOrganization) => x.name),
      sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1.join(', '), v2.join(', '), param1, param2),
      type: 'list',
      filterOperators: listOperators,
    },
    {
      field: 'domains',
      headerName: 'DOMAINS',
      width: 300,
      renderCell: (params) => {
        if (getValues()[`master_${params.row.id}`] && getValues()[`admin_${params.row.id}`]) {
          return null;
        }
        const orgs = getValues()[`organization_${params.row.id}`] || params.row.organizations.map((x: IProfileOrganization) => x.id);
        const orgDomains: IProfileDomain[] = _.sortBy(
          orgs.flatMap((id: number) => (
            organizations.find((y) => y.id === id)?.domains || []
          )),
          'url',
        );
        return (
          <FormSelect.MultiSelect
            name={`domain_${params.row.id}`}
            options={orgDomains.map((domain) => ({ id: domain.id, label: domain.url }))}
            control={control}
            formControlSx={{ width: '100%' }}
            selectSx={{
              width: '100% !important',
              boxShadow: 'none',
              backgroundColor: 'white',
              border: 0,
              '& .MuiSelect-select': {
                padding: '9px 40px 9px 4px !important',
              },
            }}
            showCheckbox
            showAllItem
          />
        );
      },
      editable: false,
      valueGetter: ({ row }) =>
        _.sortBy(
          allDomains.filter(({ id }) => (getValues()[`domain_${row.id}`] || []).includes(id)),
          'url',
        ).map((x: IProfileDomain) => x.url),
      sortComparator: (v1, v2, param1, param2) =>
        gridStringOrNumberComparator(v1.join(', '), v2.join(', '), param1, param2),
      type: 'list',
      filterOperators: listOperators,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 50,
      renderCell: (params) => [
        <IconButton
          key={params.id}
          onClick={() => {
            setDeletingUser(params.row);
            setIsDeleting(true);
          }}
        >
          <DeleteIcon />
        </IconButton>,
      ],
      filterable: false,
      sortable: false,
    },
  ];
  const [searchText, setSearchText] = React.useState<string>('');
  const deferredText = React.useDeferredValue(searchText.toLowerCase());
  const tableData = React.useMemo(
    () =>
      users.filter((row) => (
        row.firstName.toLowerCase().includes(deferredText)
        || row.lastName.toLowerCase().includes(deferredText)
        || row.email.toLowerCase().includes(deferredText)
        || row.organizations.map((x) => x.name).join('').toLowerCase().includes(deferredText)
        || row.domains.map((x) => x.url).join('').toLowerCase().includes(deferredText)
      )),
    [deferredText, users]
  );
  users.forEach((user) => {
    watch(`firstName_${user.id}`);
    watch(`lastName_${user.id}`);
    watch(`email_${user.id}`);
    watch(`reports_${user.id}`);
    watch(`admin_${user.id}`);
    watch(`master_${user.id}`);
    watch(`organization_${user.id}`);
    watch(`domain_${user.id}`);
  });
  const values = getValues();
  const masterDomainId = profile.organizations.find((x) => x.master)?.domains[0].id;
  const updatedUsers = users.flatMap((user) => {
    const prevUser = {
      ..._.pick(user, ['id', 'firstName', 'lastName', 'email', 'reports', 'onepage', 'admin']),
      domains: user.domains.map((x) => x.id),
    };
    const newUser = {
      id: user.id,
      firstName: values[`firstName_${user.id}`],
      lastName: values[`lastName_${user.id}`],
      email: values[`email_${user.id}`],
      reports: values[`reports_${user.id}`],
      onepage: values[`onepage_${user.id}`],
      admin: values[`admin_${user.id}`],
      domains: [
        ...(values[`master_${user.id}`] ? [masterDomainId] : []),
        ...(values[`domain_${user.id}`] || []),
      ],
    };
    if (_.isEqual(newUser, prevUser)) {
      return [];
    }
    return [newUser];
  });
  const onSubmit = async () => {
    await Promise.all(updatedUsers.map(updateUser));
    const newUsers = _.uniqBy([
      ...updatedUsers.map((user) => ({
        ...user,
        organizations: _.uniqBy(
          user.domains.map((id) => profile.organizations.find((x) => (
            x.domains.some((y) => y.id === id)
          )) as IProfileOrganization),
          (x) => x.id,
        ),
        domains: user.domains.map((id) => (
          profile.organizations
            .flatMap((x) => x.domains)
            .find((x) => (x.id === id)) as IProfileDomain
        )),
      })),
      ...users,
    ], (x) => x.id);
    updateUsers(newUsers);
  };

  return (
    <Box>
      <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
        <TableActionBar
          searchText={searchText}
          setSearchText={(value: string) => setSearchText(value)}
          onDiscard={() => {
            reset(defaultFormValues);
          }}
          disabled={updatedUsers.length === 0}
        />
        <Box sx={{ height: '500px', marginTop: 2 }}>
          <CustomDataGrid
            columns={columns}
            rows={tableData}
            hideFooter
            initialSortModel={[{ field: 'email', sort: 'asc' }]}
            columnVisibilityModel={{
              master: isMetrical,
            }}
          />
        </Box>
      </FormContainer>
      <CustomModal
        open={isDeleting}
        onClose={() => setIsDeleting(false)}
        onConfirm={() => setIsDeleting(false)}
        onCancel={async () => {
          if (deletingUser) {
            await deleteUser(deletingUser.id);
            updateUsers(users.filter((x) => x.id !== deletingUser.id));
            setIsDeleting(false);
          }
        }}
        content={`Are you sure you want to delete ${deletingUser?.firstName} ${deletingUser?.lastName}?`}
        actionButtonLabels={{ cancel: 'delete', confirm: 'cancel' }}
      />
      <CustomModal
        open={isMultiModal}
        onClose={() => setIsMultiModal(false)}
        onCancel={() => setValue(`organization_${organizationId}`, [])}
        content={
          <>
            Providing access across multiple organizations:
            <ul style={{ marginBottom: 0, paddingLeft: 30 }}>
              {nonMetricalOrgs &&
                nonMetricalOrgs.map((item: string | number) => (
                  <li key={item}>
                    {organizations.find(({ id }) => id.toString() === item.toString())?.name}
                  </li>
                ))}
            </ul>
          </>
        }
      />
    </Box>
  );
}
