import { Dispatch, SetStateAction, useState } from 'react';

const isCallable = <T>(x: T | unknown): x is T => typeof x === 'function';

export default function usePersistentState<T>(key: string, defaultState: T): [T, Dispatch<SetStateAction<T>>] {
  const savedState = localStorage.getItem(key);
  const initialState: T = savedState ? JSON.parse(savedState) : defaultState;
  const [state, setState] = useState(initialState);
  return [
    state,
    (newState) => {
      const newValue = isCallable<Function>(newState) ? newState(state) : newState;
      if (newValue) {
        localStorage.setItem(key, JSON.stringify(newValue));
      } else {
        localStorage.removeItem(key);
      }
      setState(newValue);
    },
  ];
}
