import React, { useState } from 'react';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import copy from 'copy-to-clipboard';

import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { shortenLink } from '../apis/shortLinks';
import { useQuery } from '../utils/QueryParamsContext';

export default function Share({ isExec }: { isExec?: boolean; }) {
  const query = useQuery();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'share-popover' : undefined;
  const [url, setUrl] = useState<string | null>(null);
  return (
    <>
      <Tooltip title="Get link to share current View.">
        <IconButton
          sx={{
            marginLeft: '4px',
            display: { xs: 'none', lg: 'inline-flex' },
          }}
          onClick={async (event) => {
            const target = event.currentTarget;
            const short = await shortenLink(`${isExec ? 'exec' : 'dashboard'}?${query}`);
            setUrl(short);
            copy(short);
            setAnchorEl(target);
          }}
        >
          <ShareIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          sx: {
            padding: '16px 18px',
            border: 'none',
          },
        }}
      >
        <Typography sx={{ fontSize: 14 }}>
          {url}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>
          Link copied to clipboard
        </Typography>
      </Popover>
    </>
  );
}
