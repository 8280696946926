import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internal/models';

import usePersistentState from '../hooks/usePersistentState';
import { ICampaignOrOffer } from '../types/global';

type Params = Record<string, string>;

interface ContextValue {
  params: Params;
  query: string;
  setStateParam: (key: string, value: string) => void;
  clearParams: () => void;
}

export const QueryParamsContext = createContext<ContextValue>({
  params: {},
  query: '',
  setStateParam: () => {},
  clearParams: () => {},
});

export function QueryParamsProvider({ children }: { children: React.ReactElement }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useRef(Object.fromEntries(searchParams));
  useEffect(() => {
    setSearchParams(new URLSearchParams());
  }, [setSearchParams]);
  const [stateParams, setStateParams] = useState<Params>({});
  const setStateParam = useCallback((key: string, value: string) => {
    setStateParams((currentParams) => ({
      ...currentParams,
      [key]: value,
    }));
  }, [setStateParams]);
  const clearParams = useCallback(() => {
    params.current = {};
  }, []);
  const value = useMemo(() => ({
    params: params.current,
    query: new URLSearchParams(stateParams).toString(),
    setStateParam,
    clearParams,
  }), [setStateParam, stateParams]);
  return (
    <QueryParamsContext.Provider value={value}>
      {children}
    </QueryParamsContext.Provider>
  );
}

export const useQueryParams = () => useContext(QueryParamsContext).params;

export const useQueryState = <T, >(
  key: string,
  defaultValue: T | undefined = undefined,
  serialize: (x: any) => string = JSON.stringify,
  deserialize: (x: string) => any = JSON.parse,
) => {
  const { params, setStateParam } = useContext(QueryParamsContext);
  const init = (() => {
    if (params[key]) {
      return deserialize(params[key]);
    }
    return defaultValue;
  })();
  const useStateResult = useState<T>(init);
  const [state] = useStateResult;
  useEffect(() => {
    setStateParam(key, serialize(state))
  }, [state]);
  return useStateResult;
};

export const useQueryStateCampaignOrOfferList = (key: string, defaultValue: ICampaignOrOffer[] = []) => {
  const serialize = useCallback(
    (list: ICampaignOrOffer[])=>
      JSON.stringify(
        list.map((x) => [x.domain, x.campaign, x.offer, x.keywords]),
      ),
    [],
  );
  const deserialize = useCallback(
    (x: string) =>
      JSON.parse(x).map(([domain, campaign, offer, keywords]: any) => ({
        domain,
        campaign,
        ...(offer ? { offer } : {}),
        ...(keywords ? { keywords } : {}),
      })),
    [],
  )
  return useQueryState<ICampaignOrOffer[]>(key, defaultValue, serialize, deserialize);
};

export const useQueryStateDateRange = (key: string, defaultValue: NonEmptyDateRange<Dayjs>) => {
  const serialize = useCallback(
    ([start, end]: NonEmptyDateRange<Dayjs>)=>
      JSON.stringify([start.toISOString(), end.toISOString()]),
    [],
  );
  const deserialize = useCallback(
    (x: string) => {
      const [start, end] = JSON.parse(x);
      return [dayjs(start), dayjs(end)];
    },
    [],
  )
  return useQueryState<NonEmptyDateRange<Dayjs>>(key, defaultValue, serialize, deserialize);
};

export const useQueryPersistentState = <T, >(
  key: string,
  defaultValue: T | undefined,
  serialize: (x: any) => string = JSON.stringify,
  deserialize: (x: string) => any = JSON.parse,
) => {
  const [persistentState, setPersistentState] = usePersistentState<T | undefined>(key, defaultValue);
  const persistentStateCopy = useRef(persistentState);
  const useStateResult = useQueryState<T>(key, persistentStateCopy.current, serialize, deserialize);
  const [state] = useStateResult;
  useEffect(() => {
    setPersistentState(state);
  }, [state, setPersistentState]);
  return useStateResult;
};

export const useQuery = () => useContext(QueryParamsContext).query;
