import React, { useContext, useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { ReactComponent as ArrowRightCircle } from 'assets/icons/arrow-right-circle.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as DoubleSwitch } from 'assets/icons/double-switch.svg';
import ProjectLogo from 'components/Logo';
import { HEADER } from 'config';
import dayjs from 'dayjs';

import { Popover, styled, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { getAggregatedStats } from '../../../apis/dashboard';
import { SessionContext } from '../../../auth';
import useAsyncEffect from '../../../hooks/useAsyncEffect';
import PATH_NAMES from '../../../routes/paths';
import { DashboardType, IAggregatedStats } from '../../../types/global';
import { IProfileDomain, IProfileOrganization } from '../../../types/users';
import { makeFormatCurrency, makeFormatPercent } from '../../../utils/format';

import { HeaderContext } from './context';
import LabeledNumber from './LabeledNumber';
import UserMenu from './UserMenu';

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    minHeight: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const SwitchButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: 'white',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&:not(:last-of-type)': {
    marginBottom: '4px',
  },
}))

export default function MainHeader({ isDashboard }: { isDashboard?: boolean }) {
  const theme = useTheme();
  const { profile } = useContext(SessionContext);
  const {
    domainId, setDomainId, organizationId, setOrganizationId,
    dashboardType, setDashboardType,
  } = useContext(HeaderContext);
  const [aggregatedStats, setAggregatedStats] = useState<IAggregatedStats | null>(null);
  useAsyncEffect(async () => {
    const { data } = await getAggregatedStats(domainId, dashboardType);
    setAggregatedStats(data);
  }, [domainId, dashboardType]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'switch-dashboard-popover' : undefined;
  const isExec = useMatch(PATH_NAMES.exec.root);

  if (!profile) {
    return null;
  }
  const formatCurrency = makeFormatCurrency(profile);
  const formatCurrency2 = makeFormatCurrency(profile, 2);
  const formatPercent = makeFormatPercent(profile);
  const organizations = profile.organizations.filter((x) => !x.master);
  const selectedOrg = organizations.find((x) => x.id === organizationId) as IProfileOrganization;
  const selectedDomain = selectedOrg.domains.find((x) => x.id === domainId) as IProfileDomain;
  const singleOrg = organizations.length === 1;
  const allDomains = organizations.flatMap((organization) => (
    organization.domains.map((domain) => ({ ...domain, organization }))
  ));

  return (
    <AppBar sx={{ boxShadow: 0 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          background: theme.palette.primary.main,
          padding: {
            xs: '8px 0',
            lg: '0',
          },
          alignItems: { xs: 'flex-start', lg: 'center' },
        }}
        data-testid="app-header"
      >
        <Box
          sx={{
            padding: {
              xs: '0 16px',
              lg: '0 25px 0 24px',
            },
            width: '100%',
            display: 'flex',
            flexWrap: {
              xs: 'wrap',
              lg: 'nowrap',
            },
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: {
                xs: 'none',
                lg: 'flex',
              },
              alignItems: 'center',
              width: 173,
            }}
          >
            <ProjectLogo />
            {isDashboard && selectedDomain.logoUrl && (
              <Box sx={{ maxWidth: 56, maxHeight: 36, marginLeft: '28px' }} component="img" src={selectedDomain.logoUrl} />
            )}
          </Box>
          {isDashboard && (
            <>
              <TextField
                select
                value={domainId}
                onChange={({ target }) => {
                  const newDomainId = Number(target.value);
                  setDomainId(newDomainId);
                  const newDomain = allDomains.find((x) => (
                    x.id === newDomainId
                  )) as IProfileDomain & { organization: IProfileOrganization };
                  setOrganizationId(newDomain.organization.id);
                  if (newDomain.grossRevViewOnly) {
                    setDashboardType(DashboardType.Gross);
                  }
                }}
                sx={{
                  marginLeft: { xs: '8px', lg: '42px' },
                  flexGrow: { xs: 1, lg: 0 },
                  width: { xs: 0, lg: '400px' },
                  justifyContent: 'center',
                  [theme.breakpoints.down('lg')]: {
                    flexBasis: '80px',
                  },
                }}
                inputProps={{ 'aria-label': 'Domain' }}
              >
                {allDomains.map((({ id, url, organization: { name } }) => (
                  <MenuItem value={id} key={id} sx={{ whiteSpace: 'pre' }}>
                    {!singleOrg && `${name} / `}
                    <Box component="span" sx={{ textDecoration: 'underline' }}>
                      {url}
                    </Box>
                  </MenuItem>
                )))}
              </TextField>
              {/*
              <Button
                sx={{
                  width: '34px',
                  height: '34px',
                  borderRadius: '50%',
                  background: 'white',
                  color: theme.palette.primary.main,
                  textTransform: 'uppercase',
                  minWidth: 'initial',
                  marginLeft: '14px',
                  lineHeight: '1',
                  fontSize: '12px',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.light,
                  },
                }}
              >
                Top ten
              </Button>
              */}
              {aggregatedStats && (
                <Box
                  sx={{
                    marginLeft: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    width: '412px',
                    [theme.breakpoints.down('lg')]: {
                      marginLeft: 0,
                      order: 5,
                      padding: '8px 0 0 0',
                      width: '100%',
                    },
                  }}
                >
                  {'incrementalRevenue' in aggregatedStats ? (
                    <>
                      <LabeledNumber
                        width={120}
                        number={formatCurrency({ value: aggregatedStats.incrementalRevenue })}
                        label="net new revenue"
                      />
                      <LabeledNumber
                        width={100}
                        number={formatPercent({ value: aggregatedStats.cartCreationChange }, { sign: true })}
                        label="carts created"
                      />
                      <LabeledNumber
                        width={100}
                        number={formatPercent({ value: aggregatedStats.conversionChange }, { sign: true })}
                        label="carts saved"
                      />
                    </>
                  ) : (
                    <>
                      <LabeledNumber
                        width={120}
                        number={formatCurrency({ value: aggregatedStats.grossRevenue })}
                        label="gross revenue"
                      />
                      <LabeledNumber
                        width={70}
                        number={formatCurrency2({ value: aggregatedStats.revenuePerVisit })}
                        label="rev / visit"
                      />
                      <LabeledNumber
                        width={80}
                        number={formatCurrency2({ value: aggregatedStats.revenuePerShopper })}
                        label="rev / shopper"
                      />
                    </>
                  )}
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: 10,
                      lineHeight: 1.27,
                      marginTop: 'auto',
                      fontVariant: 'all-small-caps',
                    }}
                  >
                    {`since ${dayjs(selectedDomain.start).format('MMMM YYYY')}`}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                }}
              >
                {!selectedDomain.grossRevViewOnly && !isExec && (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        background: 'white',
                        color: theme.palette.primary.main,
                        borderRadius: '4px',
                        width: '180px',
                        whiteSpace: 'nowrap',
                        '&:hover': {
                          backgroundColor: 'white',
                        },
                        [theme.breakpoints.down('lg')]: {
                          order: 3,
                          padding: '2px 16px',
                          marginLeft: '8px',
                          flex: 1,
                          flexBasis: '80px',
                        },
                      }}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          flex: 1,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {dashboardType === DashboardType.Incremental ? 'Incremental Report' : 'Gross Report'}
                      </Box>
                      <Box
                        component={DoubleSwitch}
                        sx={{ marginLeft: '4px', flexShrink: 0 }}
                      />
                    </Button>
                    <Popover
                      id={popoverId}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      onClose={() => setAnchorEl(null)}
                      PaperProps={{
                        sx: {
                          marginTop: '10px',
                          background: theme.palette.primary.main,
                          padding: '12px',
                          width: '180px',
                          [theme.breakpoints.down('lg')]: {
                            border: '1px solid white',
                          },
                        },
                      }}
                    >
                      <SwitchButton
                        fullWidth
                        sx={dashboardType === DashboardType.Incremental ? {} : {
                          background: 'white',
                          color: theme.palette.primary.main,
                          '&:hover': {
                            background: 'white',
                          },
                        }}
                        onClick={() => {
                          setDashboardType(DashboardType.Gross);
                          setAnchorEl(null);
                        }}
                      >
                        Gross Report
                      </SwitchButton>
                      <SwitchButton
                        fullWidth
                        sx={dashboardType === DashboardType.Gross ? {} : {
                          background: 'white',
                          color: theme.palette.primary.main,
                          '&:hover': {
                            background: 'white',
                          },
                        }}
                        onClick={() => {
                          setDashboardType(DashboardType.Incremental);
                          setAnchorEl(null);
                        }}
                      >
                        Incremental Report
                      </SwitchButton>
                    </Popover>
                  </>
                )}
                {!profile.onepage && (
                  <Tooltip
                    title={isExec ? 'Go to Detailed View' : 'Go to Exec View'}
                  >
                    <IconButton
                      component={Link}
                      to={isExec ? '/dashboard' : '/exec'}
                      sx={{
                        marginLeft: '12px',
                        color: isExec ? '#8E8E8E' : 'white',
                        borderRadius: '50%',
                        padding: 0,
                        '&, &:hover': {
                          backgroundColor: isExec ? 'white' : 'transparent',
                        },
                        '& > svg': {
                          width: '30px',
                          height: '30px',
                        },
                      }}
                    >
                      <ArrowRightCircle />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Box
                sx={{
                  display: 'none',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  paddingLeft: '8px',
                  flexGrow: { xs: 1, lg: 0 },
                  width: { xs: 0, lg: '210px' },
                  [theme.breakpoints.down('lg')]: {
                    flexBasis: '80px',
                  },
                }}
              >
                {organizations.length > 1 && (
                  <TextField
                    select
                    value={organizationId}
                    onChange={({ target }) => {
                      const newOrganizationId = Number(target.value);
                      const newOrganization = organizations.find((x) => x.id === newOrganizationId) as IProfileOrganization;
                      setOrganizationId(newOrganizationId);
                      setDomainId(newOrganization.domains[0].id);
                      setDashboardType(newOrganization.domains[0].grossRevViewOnly ? DashboardType.Gross : DashboardType.Incremental);
                    }}
                    sx={{ width: '100%' }}
                    inputProps={{ 'aria-label': 'Client' }}
                  >
                    {organizations.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Box>
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              order: {
                xs: -1,
                lg: 'initial',
              },
            }}
          >
            <UserMenu />
          </Box>
          {!isDashboard && (
            <IconButton
              component={Link}
              to="/dashboard"
              sx={{
                color: 'white',
                [theme.breakpoints.up('lg')]: {
                  display: 'none',
                },
              }}
            >
              <ChevronLeft />
            </IconButton>
          )}
        </Box>
      </ToolbarStyle>
    </AppBar>
  );
}
