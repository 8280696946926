import React, { useContext, useState } from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { Link, Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Button from '@mui/material/Button';

import { SessionContext, supabase } from '../../auth';
import SocialSignIn from '../../components/Auth/SocialSignIn';
import { ErrorMessage, StyledDivider, StyledTypography } from '../../components/Auth/Styled';
import PasswordField from '../../components/Common/PasswordField';
import paths from '../../routes/paths';

interface LoginForm {
  email: string;
  password: string;
}

export default function Login() {
  const { setProfile } = useContext(SessionContext);
  const formContext = useForm<LoginForm>({
    resolver: yupResolver(yup.object().shape({
      email: yup.string().required().email('Invalid email address'),
      password: yup.string().required(),
    })),
  });
  const { handleSubmit } = formContext;
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  if (loggedIn) {
    const path = window.sessionStorage.getItem('after-login-path');
    if (path) {
      return <Navigate to={path} />;
    }
    return (
      <Navigate to={paths.dashboard.root} />
    );
  }
  const onSubmit = async ({ email, password }: LoginForm) => {
    await supabase.auth.signOut();
    setProfile(null);
    setInvalidCredentials(false);
    const { error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      setInvalidCredentials(true);
    } else {
      setLoggedIn(true);
    }
  };
  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(onSubmit)}
      FormProps={{ style: { display: 'flex', flexDirection: 'column' } }}
    >
      {invalidCredentials && (
        <ErrorMessage>
          Wrong email or password.
        </ErrorMessage>
      )}
      <TextFieldElement label="Email" name="email" sx={{ marginBottom: '12px' }} />
      <PasswordField label="Password" name="password" sx={{ marginBottom: '6px' }} />
      <Button
        size="small"
        sx={{
          alignSelf: 'flex-start',
          padding: '0 12px',
        }}
        component={Link}
        to={paths.auth.forgotPassword}
        state={{ email: formContext.watch('email') }}
      >
        Forgot your password?
      </Button>
      <Button variant="contained" sx={{ alignSelf: 'flex-end' }} type="submit">
        Log in
      </Button>
      <SocialSignIn />
      <StyledTypography
        sx={{
          marginTop: '44px',
          marginBottom: '22px',
        }}
      >
        By signing in, you are indicating that you agree to the Terms and Conditions.
      </StyledTypography>
      <StyledDivider />
      <StyledTypography
        sx={{
          marginTop: '12px',
        }}
      >
        Don’t have an account?
      </StyledTypography>
      <Button
        size="small"
        sx={{
          alignSelf: 'flex-start',
          padding: '0 12px',
          marginTop: '6px',
        }}
        component={Link}
        to={paths.auth.register}
      >
        Sign up
      </Button>
    </FormContainer>
  );
}
