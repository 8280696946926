export type CampaignDevice = 'Desktop' | 'Tablet' | 'Mobile';
export type CampaignProduct = 'cs' | 'cc' | 'rule';

export interface ICommonCampaignRow {
  id: string;
  path: string[];
  offer?: string;
  keywords: string[];
  campaign: string;
  domain: number;
  device?: CampaignDevice;
  starts: string;
  ends: string;
  products: CampaignProduct[];
  offerAttractiveness: number;
  significant?: boolean;
}

export interface IIncrementalRow extends ICommonCampaignRow {
  incrementalRevenue: number;
  conversionChange: number;
  cartCreationChange: number;
  testGroup: ['control', 'treatment'];
  targetedVisits: [number | undefined, number | undefined];
  offers: [number | undefined, number | undefined];
  carts: [number | undefined, number | undefined];
  cartCreationRate: [number | undefined, number | undefined];
  cartCreationRateDiff: number;
  purchases: [number | undefined, number | undefined];
  purchaseRate: [number | undefined, number | undefined];
  purchaseRateDiff: number;
  aov: [number | undefined, number | undefined];
  grossRevenue: [number | undefined, number | undefined],
  revenuePerVisit: [number | undefined, number | undefined],
  revenuePerShopper: [number | undefined, number | undefined],
  visits: [number | undefined, number | undefined];
}

export interface IGrossRow extends ICommonCampaignRow {
  shoppers: number;
  visits: number;
  targetedVisits: number;
  offers: number;
  carts: number;
  cartCreationRate: number;
  purchases: number;
  purchaseRate: number;
  aov: number;
  grossRevenue: number;
  revenuePerVisit: number;
  revenuePerShopper: number;
}

export interface IIncrementalChartPoint {
  date: string;
  purchaseRate: [number | undefined, number | undefined];
  newNetRevenue?: number;
  cartCreationChange?: number;
  purchaseRateChange?: number;
  aov: [number | undefined, number | undefined];
  aovDiff?: number;
  offers: [number | undefined, number | undefined];
  carts: [number | undefined, number | undefined];
  purchases: [number | undefined, number | undefined];
  purchasedValue: [number | undefined, number | undefined];
  grossRevenue?: number;
  visits: [number | undefined, number | undefined];
  shoppers: [number | undefined, number | undefined];
  revenuePerVisit: [number | undefined, number | undefined];
  revenuePerShopper: [number | undefined, number | undefined];
}

export interface IGrossChartPoint {
  date: string;
  purchaseRate?: number;
  cartCreationRate?: number;
  aov?: number;
  purchases?: number;
  offers?: number;
  purchasedValue?: number;
  carts?: number;
  visits?: number;
  shoppers?: number;
  grossRevenue?: number;
  revenuePerVisit?: number;
  revenuePerShopper?: number;
}

export type IChartPoint = IIncrementalChartPoint | IGrossChartPoint;

export interface INonEmptyChartPoint extends IIncrementalChartPoint {
  purchases: [number, number];
  offers: [number, number];
  aov: [number, number];
  carts: [number, number];
}

export interface ICampaignOfferTrend {
  campaign: string;
  offer?: string;
  points: IChartPoint[];
}

export interface ITrendWithProducts extends ICampaignOfferTrend {
  products: CampaignProduct[];
}

export interface INonEmptyTrend extends ITrendWithProducts {
  points: INonEmptyChartPoint[];
}

export interface ISummaryTrend {
  isSummary: true;
  points: IChartPoint[];
}

export type IChartTrend = ICampaignOfferTrend | ISummaryTrend;

export interface ICampaignOrOffer {
  offer?: string;
  keywords?: string[];
  campaign: string;
  domain: number;
}

export interface IIncrementalAggregatedStats {
  incrementalRevenue: number;
  cartCreationChange: number | null;
  conversionChange: number | null;
  grossRevenue: number;
}

export interface IGrossAggregatedStats {
  grossRevenue: number;
  revenuePerVisit: number | null;
  revenuePerShopper: number | null;
}

export type IAggregatedStats = IIncrementalAggregatedStats | IGrossAggregatedStats;

export enum DashboardType {
  Incremental,
  Gross,
}

export interface IDomain {
  id: string;
  name: string;
  organization: string;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  organizations: string[];
  domains: string[];
  isAdmin: boolean;
  reportsOnly: boolean;
}

export interface IUploaded {
  uploadedId: string;
  uploadedUrl: string;
}
