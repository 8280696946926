import React, { useContext, useMemo, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { HEADER } from 'config';
import Footer from 'layouts/Footer';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import { SessionContext } from '../../auth';
import paths from '../../routes/paths';
import { DashboardType } from '../../types/global';
import { IProfileOrganization } from '../../types/users';
import { useQueryPersistentState } from '../../utils/QueryParamsContext';

import { HeaderContext } from './header/context';
import MainHeader from './header';

export interface MainLayoutProps {
  isDashboard?: boolean;
}

export default function MainLayout({ isDashboard }: MainLayoutProps) {
  const { session, profile } = useContext(SessionContext);
  const theme = useTheme();
  const initialOrganization = profile?.organizations.find((x) => !x.master) as IProfileOrganization;
  const [domainId, setDomainId] = useQueryPersistentState('domain-id', initialOrganization?.domains[0].id);
  const [organizationId, setOrganizationId] = useQueryPersistentState('organization-id', initialOrganization?.id);
  const domain = profile?.organizations.find((x) => x.id === organizationId)
      ?.domains.find((x) => x.id === domainId);
  const [dashboardType, setDashboardType] = useState(
      domain?.grossRevViewOnly ? DashboardType.Gross : DashboardType.Incremental,
  );
  const contextValue = useMemo(() => ({
    domainId,
    setDomainId,
    organizationId,
    setOrganizationId,
    dashboardType,
    setDashboardType,
  }), [domainId, setDomainId, organizationId, setOrganizationId, dashboardType, setDashboardType]);

  if (!session) {
    const { pathname, search } = window.location;
    window.sessionStorage.setItem(
      'after-login-path',
      `${pathname}${search}`,
    );
    return (
      <Navigate to={paths.auth.login} />
    );
  }

  if (!profile || profile.organizations.length === 0) {
    return (
      <Navigate to={paths.auth.accessPending} />
    );
  }

  if (!domain) {
    setDomainId(initialOrganization?.domains[0].id);
    setOrganizationId(initialOrganization?.id);
  }

  return (
    <HeaderContext.Provider value={contextValue}>
      <Stack
        sx={{
          minHeight: 1,
          backgroundColor: theme.palette.secondary.lighter,
          ...(isDashboard ? {
            maxWidth: '100vw',
            overflowX: 'hidden',
          } : {}),
        }}
      >
        <MainHeader isDashboard={isDashboard} />
        <Box
          component="main"
          sx={{
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT}px`,
              lg: `${HEADER.MAIN_DESKTOP_HEIGHT}px`,
            },
            minHeight: 'calc(100vh - 26px)',
          }}
          data-testid="main"
        >
          <Container maxWidth={false}>
            <Outlet />
          </Container>
        </Box>
        <Footer />
      </Stack>
    </HeaderContext.Provider>
  );
}
