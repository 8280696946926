import React, { forwardRef, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { ReactComponent as HorizontalBarChartIcon } from 'assets/icons/horizontal-bar-chart.svg';
import { ReactComponent as PieChartIcon } from 'assets/icons/pie-chart.svg';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { EChartsOption } from 'echarts';
import EChart from 'echarts-for-react';
import _ from 'lodash';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { SessionContext } from '../../auth';
import DashboardSelect from '../../components/DataGrid/DashboardSelect';
import { IProfile } from '../../types/users';
import { makeFormatCurrency } from '../../utils/format';
import { useQueryState } from '../../utils/QueryParamsContext';

import { ChartType, DataLabelType } from './types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ArcElement,
);

const SHOW_LABEL_THRESHOLD = 0.3;

interface ChartProps {
  id: string;
  title: string;
  data: { label: string; value: number; color: string; }[];
  dataLabelType: DataLabelType;
  chartTypes: ChartType[];
}

export default forwardRef<ChartJS, ChartProps>(({ id, title, data, dataLabelType, chartTypes }: ChartProps, ref) => {
  const [chartType, setChartType] = useQueryState<ChartType>(`exec-chart-${id}`, chartTypes[0]);
  const { profile } = useContext(SessionContext) as { profile: IProfile };
  const formatCurrency = makeFormatCurrency(profile);
  const formatCurrency2 = makeFormatCurrency(profile, 2);

  const formatDataLabel = (value: number, type: DataLabelType) => {
    if (type === DataLabelType.Number) {
      return {
        labelInside: '',
        labelOutside: `${value}`,
      };
    }
    if (type === DataLabelType.Currency) {
      return {
        labelInside: '',
        labelOutside: formatCurrency({ value }),
      };
    }
    if (type === DataLabelType.Currency2) {
      return {
        labelInside: '',
        labelOutside: formatCurrency2({ value }),
      };
    }
    return {
      labelInside: '',
      labelOutside: '',
    };
  };
  const fieldValues = data.map((x) => x.value);
  const total = _.sum(fieldValues);
  const getChartjsData = () => {
    const labelsInside = fieldValues.map((x) => formatDataLabel(x, dataLabelType).labelInside);
    const labelsOutside = fieldValues.map((x) => formatDataLabel(x, dataLabelType).labelOutside);
    return {
      datasets: [{
        axis: 'y',
        data: fieldValues,
        backgroundColor: data.map((x) => x.color),
        labelsInside,
        labelsOutside,
        outlabels: {
          text: labelsInside,
        },
        total,
      }],
      labels: data.map((x) => x.label),
    };
  };
  const echartOption: EChartsOption = {
    textStyle: {
      fontFamily: 'Soleil',
      fontSize: 14,
      color: '#4559A7',
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      icon: 'circle',
      itemWidth: 12,
      itemHeight: 12,
      textStyle: {
        color: '#4559A7',
        padding: [0, 0, 0, 8],
      },
    },
    series: [
      {
        type: 'pie',
        radius: ['25%', '50%'],
        center: ['55%', '60%'],
        labelLine: {
          length: 10,
          length2: 10,
        },
        data: data.map((x) => {
          const { labelInside, labelOutside } = formatDataLabel(x.value, dataLabelType);
          return {
            name: x.label,
            value: x.value,
            labelTop: labelInside,
            labelBottom: labelOutside,
            itemStyle: {
              color: x.color,
            },
          };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 4,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label: {
          color: '#4559A7',
          lineHeight: 20,
          formatter: ({ data: { labelTop, labelBottom } }: any) => {
            if (labelTop) {
              return `{top|${labelTop}}\n{bottom|${labelBottom}}`;
            }
            return `{bottom|${labelBottom}}`;
          },
          rich: {
            top: {},
            bottom: {
              fontWeight: 800,
            },
          },
        },
        tooltip: {
          formatter: ({ name, data: { labelTop, labelBottom } }: any) => {
            if (labelTop) {
              return `${name}: ${labelTop} — ${labelBottom}`;
            }
            return `${name}: ${labelBottom}`;
          },
        },
      }
    ]
  };
  return (
    <Box
      sx={{
        flex: 1,
        margin: '0 8px',
        boxShadow: `
          0px 5px 14px 0px #8E8E8E1F,
          0px 2px 10px 0px #8E8E8E24,
          0px 2px 5px 0px #8E8E8E33
        `,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: 'calc((100vw - 166px - 16px - 48px - 32px) / 3)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '48px',
          padding: '0 25px 0 15px',
          flexShrink: 0,
        }}
      >
        <Box
          sx={{
            fontSize: '14px',
            fontWeight: '800',
            color: '#4559A7',
            textTransform: 'lowercase',
            fontVariant: 'small-caps',
          }}
        >
          {title}
        </Box>
        {chartTypes.length > 1 && (
          <DashboardSelect
            select
            inputProps={{ 'aria-label': 'Chart type' }}
            value={chartType}
            onChange={({ target }) => setChartType(target.value as unknown as ChartType)}
            SelectProps={{
              renderValue: (value) => {
                if (value === ChartType.Bar) {
                  return <HorizontalBarChartIcon/>;
                }
                if (value === ChartType.Donut) {
                  return <PieChartIcon />;
                }
                return null;
              },
              MenuProps: {
                sx: {
                  '& svg': {
                    marginRight: '16px',
                  },
                },
              },
            }}
            sx={{
              marginLeft: '16px',
              flexShrink: 0,
              '& .MuiSelect-select svg': {
                verticalAlign: 'middle'
              },
            }}
          >
            {chartTypes.includes(ChartType.Bar) && (
              <MenuItem value={ChartType.Bar}>
                <HorizontalBarChartIcon />
                Bar Chart
              </MenuItem>
            )}
            {chartTypes.includes(ChartType.Donut) && (
              <MenuItem value={ChartType.Donut}>
                <PieChartIcon />
                Pie Chart
              </MenuItem>
            )}
          </DashboardSelect>
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          background: 'white',
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '311px',
          maxHeight: '311px',
          position: 'relative',
        }}
      >
        {chartType === ChartType.Bar ? (
          <Bar
            ref={ref as any}
            data={getChartjsData()}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  left: 10,
                  top: 50,
                  bottom: 50,
                  right: 70,
                },
              },
              indexAxis: 'y',
              scales: {
                y: {
                  type: 'category',
                  ticks: {
                    padding: 10,
                    color: '#4559A7',
                    font: { size: 14 },
                  },
                  grid: { display: false },
                  border: { display: false },
                },
                x: {
                  grid: {
                    color: (ctx) => (
                      ctx.tick.value === 0 ? '#6A7AB8' : 'transparent'
                    ),
                  },
                  ticks: { display: false },
                  border: { display: false },
                  beginAtZero: true,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: (item: any) => {
                      const { dataset, dataIndex } = item;
                      const labelInside = dataset.labelsInside[dataIndex];
                      const labelOutside = dataset.labelsOutside[dataIndex];
                      if (labelInside) {
                        return `${dataset.labelsInside[dataIndex]} — ${dataset.labelsOutside[dataIndex]}`;
                      }
                      return labelOutside;
                    },
                  },
                },
                datalabels: {
                  labels: {
                    inside: {
                      formatter: (value, ctx) => {
                        if (value / (ctx.dataset as any).total < SHOW_LABEL_THRESHOLD) {
                          return null;
                        }
                        return (ctx.dataset as any).labelsInside[ctx.dataIndex];
                      },
                      align: 'left',
                      anchor: 'end',
                      color: 'white',
                      font: {
                        size: 14,
                        weight: 'bolder',
                      },
                    },
                    outside: {
                      formatter: (value, ctx) => (ctx.dataset as any).labelsOutside[ctx.dataIndex],
                      align: 'end',
                      anchor: 'end',
                      color: '#4559A7',
                      font: {
                        size: 14,
                        weight: 'bolder',
                      },
                    },
                  },
                },
              },
              datasets: {
                bar: {
                  borderRadius: 5,
                  barThickness: 24,
                },
              },
            }}
            plugins={[ChartDataLabels]}
          />
        ) : (
          <Box sx={{ width: '100%', height: '100%' }}>
            <EChart
              option={echartOption}
              ref={(instance) => {
                if (ref && 'current' in ref && instance) {
                  // eslint-disable-next-line no-param-reassign
                  ref.current = instance.getEchartsInstance() as any;
                }
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
});
