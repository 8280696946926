import { useEffect, useRef } from 'react';
import _ from 'lodash';

export default function useUpdateEffect(callback: () => void, deps: any[]) {
  const prevDeps = useRef(deps);
  useEffect(() => {
    if (!_.isEqual(deps, prevDeps.current)) {
      callback();
    }
    prevDeps.current = deps;
  }, [callback, ...deps]);
}
