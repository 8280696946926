import axios from 'axios';

import { API_URL } from './config';

export const shortenLink = async (path: string): Promise<string> => {
  const { data } = await axios.post(`${API_URL}/url-shorten`, { path });
  return data.shortUrl;
};

export const extractShortLink = async (code: string): Promise<string> => {
  const { data } = await axios.get(`${API_URL}/url-shorten`, { params: { code } });
  return data.fullUrl;
};
