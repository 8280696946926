import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { extractShortLink } from '../apis/shortLinks';
import useAsyncEffect from '../hooks/useAsyncEffect';

export default function ShortLink() {
  const { code } = useParams<{ code: string }>();
  const [path, setPath] = useState<string | null>(null);
  useAsyncEffect(async () => {
    if (code) {
      const url = new URL(await extractShortLink(code));
      setPath(`${url.pathname}${url.search}`);
    }
  }, [code]);
  if (path) {
    return <Navigate to={path} />;
  }
  if (!code) {
    return <Navigate to="/" />
  }
  return null;
}
